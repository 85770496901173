<!-- FOCUS HOLE -->
<div
	*ngIf="active === true"
	id="tut-focusHole"
	class="tut-focusHole"
	[class.hidden]="tutorialTopics[curTopic].steps[curStep].noElement || busy"
></div>

<!-- SHADOW OVERLAY -->
<!-- <div class="tut-overlay" (click)="!busy ? Close() : null"></div> -->

<!-- CHAT BUBBLE -->
<div class="tut-message tut-message-card-style" id="tut-message">
	<!-- BUBBLE ARROW -->
	<div
		*ngIf="msg && !tutorialTopics[curTopic].steps[curStep].noElement && !busy"
		class="tut-arrow"
		[ngClass]="{
			'tut-arrow-top': msgIsBottom,
			'tut-arrow-bottom': msgIsTop,
			'tut-arrow-left': msgIsLeft,
			'tut-arrow-right': msgIsRight
		}"
	></div>

	<!-- BUBBLE CONTENT -->
	<zui-dialogbox
		[attr.header-text]="curTitle"
		*ngIf="active === true && !busy"
		confirm-position="right"
		closable
		(close)="Close()"
		[attr.accept-label]="
			curTopic >= tutorialTopics.length - 1 && curStep >= tutorialTopics[curTopic].steps.length - 1
				? 'Close'
				: 'Next'
		"
		cancel-label="Previous"
		(accept)="
			curTopic >= tutorialTopics.length - 1 && curStep >= tutorialTopics[curTopic].steps.length - 1
				? Close()
				: forward()
		"
		[visAttribute]="{
			'disable-cancel': curTopic === 0 && curStep < 1 ? true : null
		}"
		(cancel)="backward()"
	>
		<div class="tut-text" [innerHtml]="curMessage"></div>

		<!-- TOPICS -->
		<div class="tut-topic-cont">
			<ng-container *ngFor="let topic of tutorialTopics; index as i">
				<ng-container *ngIf="topic.steps.length > 0">
					<!-- STEPS -->
					<ul class="tut-step-cont">
						<li
							(click)="moveDirect(i, j)"
							*ngFor="let step of topic.steps; index as j"
							class="tut-step"
							[ngClass]="{
								'tut-step-active': curTopic === i && curStep === j,
								'tut-step-not-seen': !settings.Settings.system_tutorialStepsSeen?.includes(step.id),
								'tut-step-seen': settings.Settings.system_tutorialStepsSeen?.includes(step.id)
							}"
						>
							<span>{{ step.title }} </span>
						</li>
					</ul>
				</ng-container>
			</ng-container>
		</div>
	</zui-dialogbox>
</div>
