import { assign, keys, pick } from 'lodash-es';

export function JoinValuesIfNotEmpty(values: string[], separator?: string): string {
	const valuesValid: any[] = [];
	values.forEach((value) => {
		if (value && value.length > 0) {
			valuesValid.push(value);
		}
	});
	return valuesValid.join(separator ?? ' ');
}

export function GetPriceFormatted(price: number, decimalChar: '.' | ','): string {
	if (!price) {
		return '';
	}

	const int = Math.floor(price).toString();
	const pointCounter = Math.floor(int.length / 3);
	const pointPositions: number[] = [];
	for (let i = 1; i <= pointCounter; i++) {
		pointPositions.push(int.length - i * 3);
	}
	let intFormatted = '';
	for (let i = 0; i < int.length; i++) {
		if (i > 0 && pointPositions.includes(i)) {
			intFormatted += decimalChar === '.' ? ',' : '.';
		}
		intFormatted += int[i];
	}
	return `<span>$&nbsp;${intFormatted}${decimalChar}${price.toFixed(2).split('.')[1]}</span>`;
}

export function GetNumberWithPlusSign(
	value?: number | null,
	fixed?: number,
	escapeForHtml?: boolean
): string {
	if (!IsNumber(value)) {
		return '';
	}
	let retVal = fixed ? value!.toFixed(fixed) : value!.toString();
	if (value! > 0) {
		retVal = `+${retVal}`;
	} else if (value === 0) {
		retVal = `±${escapeForHtml ? '&nbsp;' : ''}${retVal}`;
	}
	return retVal;
}

export function IsNumber(value: any) {
	return value !== null && value !== undefined;
}

export function FileName(fileName: string) {
	const parts = fileName.split('.');
	const ext = parts[parts.length - 1];
	parts.splice(parts.length - 1);

	return { name: parts.join('.'), ext };
}

export function FormatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) {
		return '0 Bytes';
	}
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function AssignStrict(target: any, source: any) {
	return assign(target, pick(source, keys(target)));
}

export function SecondsToMilliseconds(value?: number | null) {
	if (!IsNumber(value)) {
		return undefined;
	}

	return value! * 1000;
}

export function dataURLtoBlob(dataUrl: string) {
	const arr = dataUrl.split(',');
	const mime = arr[0].match(/:(.*?);/)![1];
	const bstr = atob(arr[1]);
	let n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}

export function Flatten(arr: any[], result: any[] = []) {
	for (let i = 0, length = arr.length; i < length; i++) {
		const value = arr[i];
		if (Array.isArray(value)) {
			Flatten(value, result);
		} else {
			result.push(value);
		}
	}
	return result;
}

export function OpenExternal(url?: string) {
	if (!url) {
		return;
	}
	window.open(url, '_blank');
}
