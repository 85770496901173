export class Settings {
	system_introductionTutorialSeen = false;
	system_tutorialStepsSeen: number[] = [];
	system_app_version = '';

	order_id: string | null = null;
	order_orderId: string | null = null;
	order_shippingCode: string | null = null;
	order_type: 'manualData' | 'rxPassData' | null = null;
	order_prefill_used_order_id: string | null = null;

	order_productId: number | null = null;
	order_engraving: string | null = null;
	order_engraving_data: string | null = null;
	order_quantity = 1;
	order_wearerAgeRangeId: number | null = null;
	order_prism: boolean | null = null;
	order_surgery: boolean | null = null;

	lens_right_sphere: number | null = null;
	lens_right_cylinder: number | null = null;
	lens_right_axis: number | null = null;
	lens_right_addition: number | null = null;

	lens_left_sphere: number | null = null;
	lens_left_cylinder: number | null = null;
	lens_left_axis: number | null = null;
	lens_left_addition: number | null = null;

	rxPass_imageData: string | null = null;
	rxPass_imageFileType: string | null = null;
	rxPass_imageBlobUri: string | null = null;
	rxPass_countryCode: string | null = null;
	rxPass_countryStateCode: string | null = null;
	rxPass_name: string | null = null;
	rxPass_size: string | null = null;
	rxPass_extension: string | null = null;

	constructor(init?: Partial<Settings>) {
		Object.assign(this, init);
	}
}
