import { App } from 'src/environments/shared';

import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const routerAnimation = trigger('routerAnimation', [
	transition(':decrement', [
		style({ position: 'relative' }),

		query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),

		group([
			query(
				':enter',
				[
					style({ opacity: 0, transform: 'translateX(-10%)' }),
					animate(
						App.RouteAnimationDuration + 'ms ease-out',
						style({ transform: 'translateX(0%)', opacity: 1 })
					),
				],
				{ optional: true }
			),
			query(
				':leave',
				[
					style({ opacity: 1 }),
					animate(
						App.RouteAnimationDuration + 'ms ease-in',
						style({ transform: 'translateX(10%)', opacity: 0 })
					),
				],
				{ optional: true }
			),
		]),
	]),

	transition(':increment', [
		style({ position: 'relative' }),

		query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),

		group([
			query(
				':enter',
				[
					style({ transform: 'translateX(10%)', opacity: 0 }),
					animate(
						App.RouteAnimationDuration + 'ms ease-out',
						style({ transform: 'translateX(0%)', opacity: 1 })
					),
				],
				{ optional: true }
			),
			query(
				':leave',
				[
					style({ opacity: 1 }),
					animate(
						App.RouteAnimationDuration + 'ms ease-in',
						style({ transform: 'translateX(-10%)', opacity: 0 })
					),
				],
				{ optional: true }
			),
		]),
	]),
]);
