<zui-ewiq-dialog state="question" role="dialog" tabindex="0">
	<zui-ewiq-dialog-headline slot="headerText" role="heading">
		Close this tutorial
	</zui-ewiq-dialog-headline>

	<zui-ewiq-dialog-question slot="questionText">
		Do you really want to close this tutorial?<br />You haven't seen the most important parts yet.
	</zui-ewiq-dialog-question>

	<zui-ewiq-dialog-description>
		You can click on the help icon on the top right corner at any time to show up this tutorial
		again.
	</zui-ewiq-dialog-description>

	<zui-ewiq-dialog-buttons slot="footer" role="button" primary-position="auto">
		<zui-dialog-button slot="secondary" emphasis="primary" (click)="dialogRef.close(true)">
			Yes
		</zui-dialog-button>
		<zui-dialog-button slot="primary" emphasis="primary-highlight" (click)="dialogRef.close()">
			No
		</zui-dialog-button>
	</zui-ewiq-dialog-buttons>
</zui-ewiq-dialog>
