import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

import { Pipe, PipeTransform } from '@angular/core';

dayjs.extend(duration);
@Pipe({
	name: 'ago',
})
export class AgoPipe implements PipeTransform {
	transform(
		value?: string | number | dayjs.Dayjs | Date,
		hideSuffix = false
	): string | dayjs.Dayjs | Date {
		if (!value) {
			return '';
		}
		return this._durationReadable(value, dayjs().toString(), hideSuffix) ?? value;
	}

	private _durationReadable(
		from: string | number | dayjs.Dayjs | Date,
		to: string,
		hideSuffix = false
	): string {
		if (this._isInfinite(to)) {
			to = dayjs().subtract(dayjs().utcOffset(), 'minutes').toString();
		}
		let duration: number;
		let durationParsed: duration.Duration;
		try {
			duration = dayjs(to).diff(dayjs(from));
			durationParsed = dayjs.duration(duration);
		} catch {
			return '';
		}

		const suffix = hideSuffix ? '' : ' ago';
		let value = 0;
		if (durationParsed.asYears() >= 1) {
			value = Math.round(durationParsed.asYears());
			return `${value} year${value === 1 ? '' : 's'}${suffix}`;
		}
		if (durationParsed.asMonths() >= 1) {
			value = Math.round(durationParsed.asMonths());
			return `${value} month${value === 1 ? '' : 's'}${suffix}`;
		}
		if (durationParsed.asDays() >= 1) {
			value = Math.round(durationParsed.asDays());
			return `${value} day${value === 1 ? '' : 's'}${suffix}`;
		}
		if (durationParsed.asHours() >= 1) {
			value = Math.round(durationParsed.asHours());
			return `${value} hour${value === 1 ? '' : 's'}${suffix}`;
		}
		if (durationParsed.asMinutes() >= 1) {
			value = Math.round(durationParsed.asMinutes());
			return `${value} min${suffix}`;
		}
		value = Math.round(durationParsed.asMilliseconds());
		return `now`;
	}

	private _isInfinite(date: string): boolean {
		return dayjs(date).isAfter(dayjs('9998-01-01'), 'year');
	}
}
