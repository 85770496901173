<div vis-layout="content primary">
	<!-- LOGGED IN BUT NO ACCESS RIGHTS -->
	<div
		vis-layout="centered"
		class="splash-sign-in"
		*ngIf="(auth.Authenthicated | async) && !userHasAccessRights"
	>
		<div style="position: relative">
			<p>The Engineering Order Portal is currently not available.</p>
		</div>
	</div>

	<!-- NOT LOGGED IN -->
	<div vis-layout="centered" class="splash-sign-in" *ngIf="!(auth.Authenthicated | async)">
		<div style="position: relative" [busy]="!(auth.AuthenthicationCompleted | async) || auth.Busy">
			<zui-button (click)="auth.SignIn()"> Sign in </zui-button>
		</div>
	</div>
</div>
