import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashComponent } from './areas/splash/splash.component';
import { LegalApiService } from './common/api/legal-api.service';
import { HeaderbarComponent } from './common/headerbar/headerbar.component';
import { ToastComponent } from './common/toast/toast.component';
import { ConfirmCloseComponent } from './common/tutorial/confirm-close/confirm-close.component';
import { TutorialComponent } from './common/tutorial/tutorial.component';
import { TutorialService } from './common/tutorial/tutorial.service';
import { UserProfileComponent } from './common/user-profile/user-profile.component';
import { GlobalImportsModule } from './shared/global-imports.module';
import { MaterialImportsModule } from './shared/material-imports.module';

@NgModule({
	declarations: [
		AppComponent,
		HeaderbarComponent,
		TutorialComponent,
		ToastComponent,
		SplashComponent,
		UserProfileComponent,
		ConfirmCloseComponent,
	],
	imports: [
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialImportsModule,
		GlobalImportsModule,
		OAuthModule.forRoot(),
	],
	providers: [TutorialService, LegalApiService],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
