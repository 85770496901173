import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Build } from './environments/build';
import { Environment } from './environments/environment';
import { App } from './environments/shared';

console.debug(
	'[APP]',
	'entered',
	`${App.Name1} ${App.Name2} ${Build.appVersion} ${Build.branch} ${Build.lastCommit}`
);

if (Environment.AppMeta.Environment === 'PROD') {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() =>
		console.debug('[APP]', `bootstrapped in ${new Date().getTime() - App.AppEntered.getTime()}ms`)
	)
	.catch((err) => console.error('[APP]', err));
