import { ROUTE_CONFIG } from 'src/app/app-routing.module';
import { AuthService } from 'src/app/common/auth/auth.service';
import { TutorialService } from 'src/app/common/tutorial/tutorial.service';
import { App } from 'src/environments/shared';

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { OrderApiService } from '../api/order-api.service';
import { AppColors } from '../app';
import { SettingsService } from '../settings/settings.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
	selector: 'vis-headerbar',
	templateUrl: './headerbar.component.html',
	styleUrls: ['./headerbar.component.scss'],
})
export class HeaderbarComponent {
	app = App;
	userMenuOpened = false;
	appColors = AppColors;

	routes = ROUTE_CONFIG;

	constructor(
		private _dialog: MatDialog,
		public orderApi: OrderApiService,
		public auth: AuthService,
		public settings: SettingsService,
		public tutorial: TutorialService
	) {}

	openUserProfile() {
		this._dialog
			.open(UserProfileComponent, {
				autoFocus: true,
				position: {
					top: '50px',
					right: '10vw',
				},
			})
			.afterClosed()
			.toPromise();
	}
}
