import { Injectable } from '@angular/core';

import { LogLevel } from '../logger/logger';
import { Toast } from './toast';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private _toasts: Toast[] = [];
	private _fadeOutDuration = 300;

	Create(title: string, message?: string, level?: LogLevel) {
		const toast = new Toast({
			title,
			message,
			level,
		});

		switch (level) {
			case LogLevel.error:
				toast.duration = 12000;
				break;

			case LogLevel.warning:
				toast.duration = 7000;
				break;
		}

		toast.secondsLeft = (toast.duration ?? 5000) / 1000;

		this._toasts.push(toast);
		this._letDisappear(toast);

		const intVal = setInterval(() => {
			const index = this.Toasts.findIndex((x) => x.timestamp === toast.timestamp);
			if (!this._toasts[index]) {
				clearInterval(intVal);
			} else {
				this._toasts[index].secondsLeft! -= 1;
			}
		}, 1000);
	}

	private _letDisappear(toast: Toast, immediate?: boolean) {
		setTimeout(
			() => {
				const index = this.Toasts.findIndex((x) => x.timestamp === toast.timestamp);
				if (this._toasts[index]) {
					this._toasts[index].willDisappear = true;
				}
			},
			immediate ? 0 : toast.duration! - this._fadeOutDuration
		);

		setTimeout(
			() => {
				const index = this.Toasts.findIndex((x) => x.timestamp === toast.timestamp);
				if (this._toasts[index]) {
					this._toasts.splice(index, 1);
				}
			},
			immediate ? this._fadeOutDuration : toast.duration
		);
	}

	Remove(toast: Toast) {
		this._letDisappear(toast, true);
	}

	get Toasts() {
		return this._toasts;
	}
}
