import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RoutesExtended } from './common/app';
import { AuthGuard } from './common/auth/auth.guard';
import { WhitelistAuthGuard } from './common/auth/whitelist-auth.guard';

export const ROUTE_CONFIG = {
	// splash: {
	// 	path: '',
	// 	title: '',
	// },
	product: {
		path: 'product',
		data: {
			animationHierarchy: 1,
			title: 'Lens Order',
			icon: 'zui-icon-focus',
		},
	},
	orders: {
		path: 'order',
		data: {
			animationHierarchy: 2,
			title: 'Your Lenses',
			icon: 'zui-icon-edf-edf-active',
		},
	},
	whitelist: {
		path: 'whitelist',
		data: {
			animationHistory: 3,
			title: 'Manage Whitelist',
			icon: 'zui-icon-user-user-best-practice-video',
		},
	},
};

export const routes: RoutesExtended = [
	// {
	// 	path: ROUTE_CONFIG.splash.path,
	// 	canActivate: [AuthGuard],
	// 	component: SplashComponent,
	// },
	{
		path: ROUTE_CONFIG.orders.path,
		loadChildren: () => import('src/app/areas/order/order.module').then((m) => m.OrderModule),
		canActivate: [AuthGuard],
		data: ROUTE_CONFIG.orders.data,
	},
	{
		path: ROUTE_CONFIG.product.path,
		loadChildren: () => import('src/app/areas/product/product.module').then((m) => m.ProductModule),
		canActivate: [AuthGuard],
		data: ROUTE_CONFIG.product.data,
	},
	{
		path: ROUTE_CONFIG.whitelist.path,
		loadChildren: () =>
			import('src/app/areas/whitelist/whitelist.module').then((m) => m.WhitelistModule),
		canActivate: [WhitelistAuthGuard],
		data: ROUTE_CONFIG.whitelist.data,
	},
	{
		path: '**',
		// redirectTo: ROUTE_CONFIG.splash.path,
		redirectTo: ROUTE_CONFIG.product.path,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
