import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import { Environment } from '../../../environments/environment';
import { ApiVersion } from '../../../environments/shared';
import { LogCat } from '../logger/logger';
import { ApiService } from './api.service';

@Injectable()
export class LegalApiService extends ApiService {
	public getLegalDocument(filename: string) {
		const id = this.StartRequest('getLegalDocument');

		let headers = new HttpHeaders();
		for (const key in Environment.API.Create.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Create.Headers, key)) {
				headers = headers.append(
					key,
					(Environment.API.Create.Headers as { [key: string]: string })[key]
				);
			}
		}
		headers = headers.append('Authorization', Environment.API.Headers.Authorization);

		return this._http
			.get(
				`${Environment.API.Create.Path}${ApiVersion['v1.0']}${Environment.API.Create.LegalDocument}${filename}`,
				{
					params: this.Params,
					headers,
					responseType: 'arraybuffer',
				}
			)
			.pipe(
				map((response) => {
					const blob = new Blob([response], { type: 'application/pdf' });
					return blob;
				}),
				tap(
					() => {},
					(e) => this.LogError(e, LogCat.legal, false, true)
				),
				finalize(() => this.EndRequest(id))
			);
	}

	public getTermsOfUseDocument() {
		return this.getLegalDocument(Environment.API.Create.TermsOfUse);
	}

	public getAuthorizationDocument() {
		return this.getLegalDocument(Environment.API.Create.Authorization);
	}
}
