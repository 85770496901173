import { AssignStrict } from 'src/app/helper/common';
import {
	Product,
	ProductConfiguration,
	SphereCylinderCombinationIsOutOfRange,
} from '../product/product';

export function orderHasOutOfRangeValues(products: Product[], order: Order) {
	const product = products.find((x) => x.id === order.product.productId);

	if (!product) {
		return undefined;
	}

	if (
		order.product.left &&
		SphereCylinderCombinationIsOutOfRange(
			product,
			order.product.left.sphere!,
			order.product.left.cylinder!
		)
	) {
		return true;
	}

	if (
		order.product.right &&
		SphereCylinderCombinationIsOutOfRange(
			product,
			order.product.right.sphere!,
			order.product.right.cylinder!
		)
	) {
		return true;
	}

	return false;
}

export class ShippingLocation {
	code?: string;
	description?: string;
	sorting?: number;

	constructor(init?: Partial<ShippingLocation>) {
		if (init) {
			Object.assign(this, init);
		}
	}
}

export enum OrderStatusEnum {
	/**
	 * The none.
	 */
	NONE = 0,

	/**
	 * The in validation.
	 */
	IN_VALIDATION = 1,

	/**
	 * The in validation - ship to location in clarification.
	 */
	IN_VALIDATION_SHIP_TO_LOCATION_IN_CLARIFICATION = -1,

	/**
	 * The information required.
	 */
	INFORMATION_REQUIRED = 2,

	/**
	 * The information required - ship to location in clarification.
	 */
	INFORMATION_REQUIRED_SHIP_TO_LOCATION_IN_CLARIFICATION = -2,

	/**
	 * The ready for production.
	 */
	READY_FOR_PRODUCTION = 3,

	/**
	 * The in deletion process.
	 */
	IN_DELETION_PROCESS = 4,

	/**
	 * The ship to location in clarification.
	 */
	SHIP_TO_LOCATION_IN_CLARIFICATION = -4,

	/**
	 * The out of range - in validation.
	 */
	OUT_OF_RANGE_IN_VALIDATION = 5,

	/**
	 * The out of range - in validation - ship to location in clarification.
	 */
	OUT_OF_RANGE_IN_VALIDATION_SHIP_TO_LOCATION_IN_CLARIFICATION = -5,

	/**
	 * The out of range validation unsuccessfull.
	 */
	OUT_OF_RANGE_VALIDATION_UNSUCCESSFULL = 6,

	/**
	 * The out of range validation rejected
	 */
	OUT_OF_RANGE_VALIDATION_REJECTED = -6,

	/**
	 * The order placed.
	 */
	ORDER_PLACED = 10,

	/**
	 * The order placed CEF OMRS.
	 */
	ORDER_PLACED_CEF_OMRS = 11,

	/**
	 * The delivered.
	 */
	DELIVERED = 13,

	/**
	 * The in production.
	 */
	IN_PRODUCTION = 81,

	/**
	 * The dispatched.
	 */
	DISPATCHED = 84,
}

export interface Country {
	name: string;
	code: string;
}

export interface OrderStatus {
	id: number;
	description: string;
	displayName?: string;
}

export interface OrderError {
	errorCode: number;
	errorCodeText: string;
	reviewerComment?: string;
}

export class Order {
	zeissId: number | null = null;
	id: string | null = null;
	orderId: string | null = null;
	orderCreationUtcTimestamp: number | null = null;
	status: OrderStatus | null = null;
	lastOrderStatusUpdateUtcTimestamp: number | null = null;
	shippingCode: string | null = null;
	shippingText: string | null = null;
	oneTimeShipTo: boolean | null = null;
	product = new ProductConfiguration();
	type: OrderType = null;
	prefillUsedOrderId: string | null = null;
	builtPhase: BuiltPhase | null = null;

	constructor(init?: Partial<Order>) {
		AssignStrict(this, init);
	}
}

export type OrderType = 'manualData' | 'rxPassData' | null;

export interface BuiltPhase {
	name: string;
	dateFromUTCTs: number;
	dateToUTCTs: number;
}

export function AsOrder(value: any) {
	return <Order>value;
}

export class RxPass {
	imageData: string | null = null;
	imageFileType: string | null = null;
	countryCode?: string | null = 'US';
	countryStateCode?: string | null = null;
	errors: OrderError[] | null = null;
	name: string | null = null;
	size: string | null = null;
	imageBlobUri?: string | null = null;
	extension: string | null = null;

	constructor(init?: Partial<RxPass>) {
		AssignStrict(this, init);
	}
}

export class WearerAgeRange {
	id: number | null = null;
	description: string | null = null;

	constructor(init?: Partial<ShippingLocation>) {
		if (init) {
			Object.assign(this, init);
		}
	}
}
