// prettier-ignore
 // this file is automatically generated by git.version.ts script
 export const Build = {
   appVersion: `1.0.0`,
   branch: `MAIN`,
   lastCommit: `a818309`,
   lastCommitUrl: `https://dev.azure.com/zathena/Digital-Lens-Portal/_git/Frontend.DigitalLensPortal/commit/a81830931c379f83a7cc384735714e30aa48f2ea`,
   lastCommitHash: `a81830931c379f83a7cc384735714e30aa48f2ea`,
   lastCommitTime: `2023-06-20T13:10:07+00:00`,
   lastCommitMessage: `Merged PR 918: Remove feature flags #997

# Description
misc: remove feature flags #997

Removes unused Feature Flags.

Related work items: #997`,
   lastCommitAuthor: `Aaron Czichon`
 };
 