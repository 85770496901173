<ul>
	<li *ngFor="let item of toast.Toasts" [class.disappearing]="item.willDisappear">
		<zui-toast-notification
			[attr.header-text]="item.title"
			state="default"
			(click)="toast.Remove(item)"
			class="pointer"
		>
			<ng-container [ngSwitch]="item.level">
				<zui-icon-ewi-error-triangle
					*ngSwitchCase="0"
					slot="icon"
					size="m"
				></zui-icon-ewi-error-triangle>
				<zui-icon-ewi-warning-triangle
					*ngSwitchCase="1"
					slot="icon"
					size="m"
				></zui-icon-ewi-warning-triangle>
				<zui-icon-ewi-check *ngSwitchCase="2" slot="icon" size="m"></zui-icon-ewi-check>
				<zui-icon-ewi-info *ngSwitchCase="3" slot="icon" size="m"></zui-icon-ewi-info>
				<zui-icon-close *ngSwitchCase="4" size="s" slot="icon"></zui-icon-close>
				<zui-icon-ewi-info *ngSwitchDefault slot="icon" size="m"></zui-icon-ewi-info>
			</ng-container>
			<span slot="icon1" *ngIf="item.secondsLeft && item.secondsLeft < 10">
				{{ item.secondsLeft }}</span
			>
			<span slot="icon1" *ngIf="item.secondsLeft && item.secondsLeft > 9"> &nbsp;</span>

			<span class="message" [innerHtml]="item.message"></span>
		</zui-toast-notification>
	</li>
</ul>
