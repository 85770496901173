import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[visAttribute]' })
export class VisAttributeDirective {
	private _host: HTMLElement;

	@Input() set visAttribute(attributes: { [key: string]: any }) {
		for (const attribute in attributes) {
			if (Object.prototype.hasOwnProperty.call(attributes, attribute)) {
				setTimeout(() => {
					if (attributes[attribute] === null || attributes[attribute] === undefined) {
						this._host.removeAttribute(attribute);
					} else {
						this._host.setAttribute(attribute, attributes[attribute]);
					}
				});
			}
		}
	}

	constructor(private el: ElementRef) {
		this._host = this.el.nativeElement;
	}
}
