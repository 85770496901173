import { OverlayModule } from '@angular/cdk/overlay';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { VisTooltipComponent } from './tooltip.component';
import { VisTooltipDirective } from './tooltip.directive';

@NgModule({
	declarations: [VisTooltipComponent, VisTooltipDirective],
	imports: [OverlayModule],
	exports: [VisTooltipDirective],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VisTooltipModule {}
