import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { TutorialService } from '../tutorial/tutorial.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private _auth: AuthService,
		private _logger: LoggerService,
		private _tutorial: TutorialService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this._tutorial.IsOpen) {
			return false;
		}
		const canActivateCustom = this.CanActivateCustom(state.url, route, state);
		return canActivateCustom;
	}

	CanActivateCustom(url: string, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
		// Remove leading '/'
		while (url.startsWith('/')) {
			url = url.substring(1);
		}

		// Remove query params from route to check
		const urlParts = url.split('?');
		const urlWithoutParams = urlParts[0];

		return new Observable<boolean>((authorized) => {
			this._auth.Authenthicated.subscribe((authenticated) => {
				if (authenticated) {
					this._logger.Log(`Allowed: ${urlWithoutParams}`, LogCat.auth, {
						level: LogLevel.success,
					});

					authorized.next(true);
					authorized.complete();
				} else {
					this._logger.Log(`Forbidden: ${urlWithoutParams}`, LogCat.auth, {
						level: LogLevel.error,
					});

					// If this CanActivate Request has been triggered because the user wants to navigate
					if (route && state) {
						this._logger.Log(`Access denied: ${urlWithoutParams}`, LogCat.auth, {
							level: LogLevel.error,
							showToast: true,
						});
					}

					authorized.next(false);
					authorized.complete();
				}
			});
		});
	}
}
