import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vis-tooltip',
	template: `<zui-tooltip [message]="text"></zui-tooltip>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('tooltip', [
			transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
			transition(':leave', [animate(300, style({ opacity: 0 }))]),
		]),
	],
})
export class VisTooltipComponent {
	@Input() text = '';
}
