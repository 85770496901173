import { Build } from 'src/environments/build';
import { App } from 'src/environments/shared';

import { EventEmitter, Injectable } from '@angular/core';

import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { Settings } from './settings';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private _settings: Settings = new Settings();
	private _path = App.LocalStorage.Prefix + App.LocalStorage.Settings;

	protected _temp: { [key: string]: any } = {};

	SettingsChanged$ = new EventEmitter<Settings>();

	constructor(private _logger: LoggerService) {
		try {
			let temp = new Settings(JSON.parse(localStorage.getItem(this._path)!));
			if (temp.system_app_version !== `${Build.branch} ${Build.appVersion} ${Build.lastCommit}`) {
				this._logger.Log(
					`settings read from local storage are from old app version. resetting them.`,
					LogCat.settings,
					{
						aiData: {
							settings: { ...this.Settings },
						},
					}
				);
				const freshSettings = new Settings();
				freshSettings.system_introductionTutorialSeen = temp.system_introductionTutorialSeen;
				freshSettings.system_tutorialStepsSeen = temp.system_tutorialStepsSeen;
				freshSettings.system_app_version = `${Build.branch} ${Build.appVersion} ${Build.lastCommit}`;
				temp = freshSettings;
			} else {
				this._logger.Log(`settings read from local storage`, LogCat.settings, {
					aiData: {
						settings: { ...temp },
					},
				});
			}
			this.Settings = temp;
		} catch (error) {
			this._logger.Log(
				`Could not read settings from LocalStorage. Using defaults.`,
				LogCat.settings,
				{
					level: LogLevel.error,
					aiData: {
						settings: { ...this.Settings },
					},
				}
			);
			this._settings = new Settings();
			this.save();
		}
	}

	get TempStorage() {
		return this._temp;
	}

	set TempStorage(value: { [key: string]: any }) {
		Object.assign(this._temp, value);
	}

	get Settings(): Partial<Settings> {
		return this._settings;
	}

	set Settings(settings: Partial<Settings>) {
		Object.assign(this._settings, settings);
		this.save();
		this._logger.Log(`settings updated to`, LogCat.settings, {
			aiData: {
				settings: { ...this.Settings },
			},
		});
	}

	private save(): void {
		try {
			localStorage.setItem(this._path, JSON.stringify(this.Settings));
		} catch (error) {
			this._logger.Log('could not write settings', LogCat.settings);
		}
		this.SettingsChanged$.emit(this._settings);
	}

	ResetToDefault(): void {
		this._logger.Log('reset settings', LogCat.settings);
		localStorage.clear();
		const newSettings = new Settings();
		this.Settings = newSettings;
		setTimeout(() => location.reload(), 500);
	}

	ClearStorage() {
		this._logger.Log('clearing local storage', LogCat.settings);
		localStorage.clear();
	}
}
