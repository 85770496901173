import { Data, Route, Routes } from '@angular/router';

export enum AppColors {
	'error' = 0,
	'warning',
	'success',
	'info',
	'grey',
	'white',
}

export type RoutesExtended = RouteExtended[];

export interface RouteExtended extends Route {
	data?: DataExtended;
}

export interface DataExtended extends Data {
	animationHierarchy?: number;
	title?: string;
	icon?: string;
	showInMenu?: boolean;
	cache?: boolean;
}
