import { Subject, timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { Flatten } from 'src/app/helper/common';

import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppColors } from '../app';
import { LogCat } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { SettingsService } from '../settings/settings.service';
import { ConfirmCloseComponent } from './confirm-close/confirm-close.component';
import { TutorialTopic, TutorialTopics } from './tutorial';
import { TutorialService } from './tutorial.service';

interface FocusDimension {
	top: number;
	right: number;
	bottom: number;
	left: number;
	height: number;
	width: number;
}

@Injectable({
	providedIn: 'root',
})
@Component({
	selector: 'vis-tutorial',
	templateUrl: './tutorial.component.html',
	styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit, OnDestroy {
	intVals: any[] = [];

	appColors = AppColors;

	public showTrig = false;
	public active = false;
	focHole!: HTMLElement;
	msg!: HTMLElement;
	public tutorialTopics: TutorialTopic[] = [];
	curTopic = 0;
	curStep = 0;
	curTitle = '';
	curMessage = '';
	focDim: FocusDimension = {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		height: 0,
		width: 0,
	};
	msgIsTop = false;
	msgIsBottom = false;
	msgIsLeft = false;
	msgIsRight = false;

	moving = false;
	busy = false;

	private destroy$ = new Subject();

	constructor(
		public settings: SettingsService,
		private _dialog: MatDialog,
		private _tutorialService: TutorialService,
		private _logger: LoggerService
	) {}

	ngOnDestroy(): void {
		this.intVals.forEach((element) => clearInterval(element));
		this.destroy$.next();
	}

	ngOnInit(): void {
		let lastTopic = -1;
		let lastStep = -1;

		if (!this.settings.Settings.system_introductionTutorialSeen) {
			// First time the user sees the tutorial -> show the 1st step

			lastTopic = 0;
			lastStep = 0;
		} else {
			// First time the user sees the tutorial in this session -> show 1st unseen step
			// if no unseen step, show 2nd topic (if there are > 1) + 1st step (1st topic is general, others are detail)

			if (this.unseenSteps.length > 0) {
				const firstUnseenStep = this.unseenSteps[0];

				lastTopic = this.tutorialTopics.findIndex((topic) =>
					topic.steps.find((step) => step.id === firstUnseenStep)
				);
				lastStep = this.tutorialTopics[lastTopic].steps.findIndex(
					(step) => step.id === firstUnseenStep
				);
			} else {
				lastTopic = this.tutorialTopics.length > 1 ? 1 : 0;
				lastStep = 0;
			}
		}

		this.curTopic = lastTopic;
		this.curStep = lastStep;

		this._tutorialService.clickOutside$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
			if (this.active) {
				this.Close();
			}
		});

		this._tutorialService.resize$
			.pipe(
				takeUntil(this.destroy$),
				debounce(() => timer(50))
			)
			.subscribe(() => {
				if (this.active) {
					this.moveFocus(true);
				}
			});

		this._tutorialService.scrollY$
			.pipe(
				takeUntil(this.destroy$),
				debounce(() => timer(25))
			)
			.subscribe(() => {
				if (this.active) {
					this.moveFocus(true);
				}
			});

		this.Open();
	}

	generateElementTexts() {
		this.tutorialTopics.forEach((topic) => {
			topic.steps.forEach((step) => {
				if (step.el && !step.noElement) {
					step.title = step.el.getAttribute('ttitle') ?? '';
					step.text = step.el.getAttribute('ttext') ?? '';
				}
			});
		});
	}

	public Open(): void {
		this.generateElementTexts();
		this._logger.Log(`Showing tutorials`, LogCat.tutorial, {
			aiData: {
				topics: this.tutorialTopics,
			},
		});
		this.active = true;

		const intVal = setInterval(() => {
			this.focHole = document.getElementById('tut-focusHole')!;
			this.msg = document.getElementById('tut-message')!;
			if (this.focHole && this.msg) {
				this.focHole.style.opacity = '1';
				this.setCurStep(this.curTopic, this.curStep);
				this.moveFocus(true);
				setTimeout(() => this.moveFocus(), 100);
				clearInterval(intVal);
			}
		}, 100);
		this.intVals.push(intVal);
	}

	get unseenSteps() {
		let temp = TutorialTopics().map((topic) => topic.steps.map((x) => x.id));

		const ids = Flatten(temp);

		const retVal: number[] = [];
		ids.forEach((id) => {
			if (!(this.settings.Settings.system_tutorialStepsSeen ?? []).includes(id)) {
				retVal.push(id);
			}
		});

		return retVal.sort((a, b) => a - b);
	}

	get introductionStepsSeen() {
		const ids = TutorialTopics()[0].steps.map((step) => step.id);

		let retVal = true;
		ids.forEach((id) => {
			if (!(this.settings.Settings.system_tutorialStepsSeen ?? []).includes(id)) {
				retVal = false;
			}
		});

		return retVal;
	}

	public Close(): void {
		this._dialog.closeAll();

		this._logger.Log(`User closes tutorial`, LogCat.tutorial, {
			aiData: {
				topics: this.tutorialTopics,
				system_introductionTutorialSeen: this.settings.Settings.system_introductionTutorialSeen,
				introductionStepsSeen: this.introductionStepsSeen,
			},
		});

		if (!this.settings.Settings.system_introductionTutorialSeen && !this.introductionStepsSeen) {
			this.busy = true;
			this._dialog
				.open(ConfirmCloseComponent)
				.afterClosed()
				.subscribe((data?: boolean) => {
					if (data) {
						this.settings.Settings = { system_introductionTutorialSeen: true };
						this.active = false;
					}
					this.busy = false;
				});
		} else if (!this.settings.Settings.system_introductionTutorialSeen) {
			this.settings.Settings = { system_introductionTutorialSeen: true };
			this.active = false;
		} else {
			this.active = false;
		}
	}

	forward(): void {
		this.setStepSeen(this.tutorialTopics[this.curTopic].steps[this.curStep].id);

		if (this.curStep >= this.tutorialTopics[this.curTopic].steps.length - 1) {
			if (this.curTopic >= this.tutorialTopics.length - 1) {
				this.Close();
				return;
			} else {
				this.setCurStep(this.curTopic + 1, 0);
				this.moveFocus(true);
			}
		} else {
			this.setCurStep(this.curTopic, this.curStep + 1);
			this.moveFocus(true);
		}
	}

	backward(): void {
		this.setStepSeen(this.tutorialTopics[this.curTopic].steps[this.curStep].id);

		if (this.curStep > 0) {
			this.setCurStep(this.curTopic, this.curStep - 1);
		} else if (this.curTopic > 0) {
			this.setCurStep(this.curTopic - 1, this.tutorialTopics[this.curTopic - 1].steps.length - 1);
		}

		this.moveFocus(true);
	}

	setCurStep(topic: number, step: number) {
		if (topic < 0 || step < 0) {
			return;
		}

		this.curTopic = topic;
		this.curStep = step;

		const id = this.tutorialTopics[this.curTopic].steps[this.curStep].id;

		setTimeout(() => {
			if (this.curTopic !== topic || this.curStep !== step) {
				return;
			}

			this.setStepSeen(id);
		}, 3000);
	}

	setStepSeen(stepId: number) {
		if (!(this.settings.Settings.system_tutorialStepsSeen ?? []).includes(stepId)) {
			this.settings.Settings = {
				system_tutorialStepsSeen: (this.settings.Settings.system_tutorialStepsSeen ?? []).concat(
					stepId
				),
			};
		}

		if (!this.introductionStepsSeen) {
		}
	}

	moveDirect(topic: number, step: number): void {
		if (topic !== this.curTopic || step !== this.curStep) {
			this.setStepSeen(this.tutorialTopics[this.curTopic].steps[this.curStep].id);
			this.setCurStep(topic, step);
			this.moveFocus(true);
		}
	}

	moveFocus(skipScroll?: boolean, skipMessageFade?: boolean): void {
		if (
			!this.focHole ||
			!this.msg ||
			(!this.tutorialTopics[this.curTopic].steps[this.curStep]?.el &&
				!this.tutorialTopics[this.curTopic].steps[this.curStep]?.noElement)
		) {
			return;
		}

		let rect: DOMRect;
		if (this.tutorialTopics[this.curTopic].steps[this.curStep].noElement) {
			rect = document.getElementById('tutorial-general-spot')!.getBoundingClientRect();
		} else {
			rect = this.tutorialTopics[this.curTopic].steps[this.curStep].el!.getBoundingClientRect();
		}
		if (!rect) {
			return;
		}

		this.focDim.top = rect.top - 5;
		this.focDim.right = document.body.clientWidth - rect.right - 5;
		this.focDim.bottom = rect.bottom - 20;
		this.focDim.left = rect.left - 5;
		this.focDim.height = rect.height + 10;

		if (rect.width + 10 >= document.body.clientWidth) {
			this.focDim.width = document.body.clientWidth - 10;
		} else {
			this.focDim.width = rect.width + 10;
		}

		this.focHole.style.left = (this.focDim.left < 4 ? 4 : this.focDim.left) + 'px';
		this.focHole.style.top = this.focDim.top + window.scrollY + 'px';
		this.focHole.style.height = this.focDim.height + 'px';
		this.focHole.style.width = this.focDim.width + 'px';
		if (!skipMessageFade) {
			this.msg.style.opacity = '0';
		}

		this.moving = false;
		setTimeout(() => {
			if (!this.moving) {
				this.moving = true;
				this.curTitle = this.tutorialTopics[this.curTopic].steps[this.curStep].title ?? '';
				this.curMessage = this.tutorialTopics[this.curTopic].steps[this.curStep].text ?? '';
				if (
					this.focDim.left < document.body.clientWidth / 2 &&
					this.focDim.left + this.focDim.width < document.body.clientWidth / 2
				) {
					this.msg.style.left = this.focDim.left + 'px';
					this.msg.style.right = 'auto';
					this.msgIsLeft = true;
					this.msgIsRight = false;
				} else {
					this.msg.style.left = 'auto';
					this.msg.style.right = this.focDim.right + 4 + 'px';
					this.msgIsLeft = false;
					this.msgIsRight = true;
				}
				const spaceTop = this.focDim.top + window.scrollY;
				const spaceBottom =
					document.body.clientHeight - (this.focDim.top + window.scrollY + this.focDim.height);
				if (spaceTop > spaceBottom) {
					// Put card on top
					this.msg.style.bottom =
						document.body.clientHeight - window.scrollY - this.focDim.top + 10 + 'px';
					this.msg.style.top = 'auto';
					this.msgIsTop = true;
					this.msgIsBottom = false;
				} else {
					// Put card on bottom
					this.msg.style.bottom = 'auto';
					this.msg.style.top = this.focDim.top + window.scrollY + this.focDim.height + 10 + 'px';
					this.msgIsTop = false;
					this.msgIsBottom = true;
				}

				// Check if card is in sight; if not, put it to top or bottom of viewport
				if (this.msgIsTop && this.msg.getBoundingClientRect().top < 10) {
					this.msg.style.bottom = 'auto';
					this.msg.style.top = window.scrollY - 5 + 'px';
				} else if (!this.msgIsTop && this.msg.getBoundingClientRect().top < 20) {
					this.msg.style.bottom = 'auto';
					this.msg.style.top = window.scrollY + 5 + 'px';
				} else if (
					this.msgIsBottom &&
					this.msg.getBoundingClientRect().bottom > window.innerHeight - 20
				) {
					this.msg.style.bottom = 'auto';
					this.msg.style.top =
						window.scrollY +
						window.innerHeight -
						this.msg.getBoundingClientRect().height -
						30 +
						'px';
				} else if (
					!this.msgIsBottom &&
					this.msg.getBoundingClientRect().bottom > window.innerHeight - 25
				) {
					this.msg.style.bottom = 'auto';
					this.msg.style.top =
						window.scrollY +
						window.innerHeight -
						this.msg.getBoundingClientRect().height -
						40 +
						'px';
				}

				if (!skipMessageFade) {
					this.msg.style.opacity = '1';
				}

				if (!skipScroll) {
					this.tutorialTopics[this.curTopic].steps[this.curStep].el?.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}
		}, 300);
	}

	topicSeen(topic: TutorialTopic) {
		const stepsSeen = topic.steps.filter((step) =>
			(this.settings.Settings.system_tutorialStepsSeen ?? []).includes(step.id)
		);

		return stepsSeen.length >= topic.steps.length;
	}
}
