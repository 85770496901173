import { App } from 'src/environments/shared';

import { SettingsService } from '../settings/settings.service';

export interface TutorialTopic {
	title: string;
	description?: string;
	shown?: boolean;
	steps: TutorialStep[];
}

export interface TutorialStep {
	id: number;
	text?: string;
	title?: string;
	el?: HTMLElement | null;
	noElement?: boolean;
}

export function TutorialTopics(settings?: SettingsService) {
	let retVal: TutorialTopic[] = [
		{
			title: `The ${App.Name1} ${App.Name2}`,
			steps: [
				{
					id: 11,
					title: `Welcome`,
					text: `Welcome to the <b>${App.Name1}</b> ${App.Name2}!<br><br>Here is a tutorial to guide you through the portal. You can easily close it by tapping anywhere outside.`,
					noElement: true,
				},
				{
					id: 12,
					el: document.getElementById('tut-config-link'),
				},
				{
					id: 13,
					el: document.getElementById('tut-config-link-edit'),
				},
				{
					id: 14,
					el: document.getElementById('tut-orders-link'),
				},
				{
					id: 15,
					el: document.getElementById('tut-help-link'),
				},
				{
					id: 16,
					el: document.getElementById('tut-userprofile-link'),
				},
			],
		},
		{
			title: 'Your lenses',
			steps: [
				{
					id: 21,
					el: document.getElementById('tut-check-orders'),
				},
				{
					id: 22,
					el: document.getElementById('tut-create-order'),
				},
			],
		},
		{
			title: 'Your prescription',
			steps: [
				{
					id: 41,
					el: document.getElementById('tut-choose-config'),
				},
				{
					id: 42,
					el: document.getElementById('tut-lensparam-sphere-right'),
				},
				{
					id: 43,
					el: document.getElementById('tut-lensparam-cylinder-right'),
				},
				{
					id: 44,
					el: document.getElementById('tut-lensparam-axis-right'),
				},
				{
					id: 45,
					el: document.getElementById('tut-lensparam-addition-right'),
				},
				{
					id: 46,
					el: document.getElementById('tut-change-config-to-auto'),
				},
				{
					id: 47,
					el: document.getElementById('tut-change-config-to-manual'),
				},
				{
					id: 48,
					el: document.getElementById('tut-upload-rxpass'),
				},
			],
		},
	];

	retVal.forEach((topic) => {
		topic.steps = topic.steps.filter((x) => x.el || x.noElement);
	});

	retVal = retVal.filter((x) => x.steps.length > 0);

	return retVal;
}
