import { filter, pullAll } from 'lodash-es';
import { ReplaySubject } from 'rxjs';

import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	EventEmitter,
	Injectable,
	Injector,
	OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LogCat } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { SettingsService } from '../settings/settings.service';
import { TutorialTopic, TutorialTopics } from './tutorial';
import { TutorialComponent } from './tutorial.component';

@Injectable({
	providedIn: 'root',
})
export class TutorialService implements OnDestroy {
	_intVals: any[] = [];
	_timeOuts: any[] = [];

	private _tutorialCompRef!: ComponentRef<TutorialComponent>;
	private _userScrollPos = 0;

	public scrollY$ = new EventEmitter<number>();
	public resize$ = new EventEmitter();
	public clickOutside$ = new EventEmitter();
	public tutorialState$ = new ReplaySubject<'OPEN' | 'CLOSED'>();
	public highlightIcon$ = new EventEmitter<boolean>(false);

	constructor(
		private _componentFactoryResolver: ComponentFactoryResolver,
		private _appRef: ApplicationRef,
		private _injector: Injector,
		private _logger: LoggerService,
		private _settings: SettingsService,
		private _dialog: MatDialog
	) {
		this.tutorialState$.next('CLOSED');
	}

	ngOnDestroy(): void {
		this._intVals.forEach((element) => clearInterval(element));
		this._timeOuts.forEach((element) => clearTimeout(element));
	}

	public get IsOpen() {
		return this._tutorialCompRef?.instance?.active;
	}

	public Open(tutorialTopics?: TutorialTopic[]) {
		this._dialog.closeAll();

		if (!tutorialTopics) {
			tutorialTopics = TutorialTopics(this._settings);
		}

		tutorialTopics.forEach((topic) => {
			pullAll(
				topic.steps,
				filter(topic.steps, (x) => !x.el && !x.noElement)
			);
		});

		if (tutorialTopics.length < 1) {
			this._logger.Log(`Sorry, no help topics found`, LogCat.tutorial, {
				showToast: true,
			});

			return;
		}

		this._userScrollPos = window.scrollY;

		this._tutorialCompRef = this._componentFactoryResolver
			.resolveComponentFactory(TutorialComponent)
			.create(this._injector);
		this._tutorialCompRef.instance.tutorialTopics = tutorialTopics;

		this._appRef.attachView(this._tutorialCompRef.hostView);
		const domElem = (this._tutorialCompRef.hostView as EmbeddedViewRef<any>)
			.rootNodes[0] as HTMLElement;
		document.getElementById('main')?.appendChild(domElem);

		const intVal = setInterval(() => {
			if (this._tutorialCompRef.instance.active === false) {
				this.Close();
				clearInterval(intVal);
			}
		}, 200);

		this._intVals.push(intVal);
		this.tutorialState$.next('OPEN');
	}

	public Close() {
		this._tutorialCompRef.instance.tutorialTopics = [];
		this._appRef.detachView(this._tutorialCompRef.hostView);
		this._tutorialCompRef.destroy();
		window.scrollTo({ top: this._userScrollPos, behavior: 'smooth' });
		this.tutorialState$.next('CLOSED');
	}
}
