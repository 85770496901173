import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

const globalRippleConfig: RippleGlobalOptions = { disabled: true };
@NgModule({
	exports: [
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatDialogModule,
		MatButtonModule,
		MatSelectModule,
		MatInputModule,
		MatListModule,
		MatCheckboxModule,
		NgxMatSelectSearchModule,
		MatTableModule,
	],
	providers: [
		{
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: globalRippleConfig,
		},
	],
})
export class MaterialImportsModule {}
