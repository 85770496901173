import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VisAttributeDirective } from './attribute.directive';

@NgModule({
	declarations: [VisAttributeDirective],
	imports: [CommonModule],
	exports: [VisAttributeDirective],
	providers: [VisAttributeDirective],
})
export class AttributeModule {}
