import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TutorialService } from '../tutorial/tutorial.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class WhitelistAuthGuard implements CanActivate {
	constructor(private _auth: AuthService, private _tutorial: TutorialService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		if (this._tutorial.IsOpen) {
			return false;
		}
		return this._auth.canManageWhitelist;
	}
}
