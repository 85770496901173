import { uniqBy } from 'lodash-es';
import { Settings } from 'src/app/common/settings/settings';
import { AssignStrict, IsNumber } from 'src/app/helper/common';
import { Order, RxPass } from '../order/order';

export function CylinderRanges(product: Product | undefined, sphere: number, outOfRange = false) {
	if (!product || !product.cylinder?.step) {
		return [];
	}

	const retVal: number[] = [];

	let cylinderRangeIndex = product.sphere.cylinderRanges.findIndex(
		(x) => x.sphere === sphere && !x.outOfRange
	);
	if (cylinderRangeIndex < 0) {
		cylinderRangeIndex = product.sphere.cylinderRanges.findIndex(
			(x) => x.sphere === sphere && x.outOfRange
		);

		if (cylinderRangeIndex < 0) {
			return retVal.sort((a, b) => a - b).reverse();
		}

		outOfRange = false;
	}
	const allowed = product.sphere.cylinderRanges[cylinderRangeIndex].cylinder;

	for (let i = allowed.min!; i <= allowed.max!; i += product.cylinder.step) {
		retVal.push(i);
	}

	if (outOfRange) {
		const cylinderRangeOutOfRangeIndex = product.sphere.cylinderRanges.findIndex(
			(x) => x.sphere === sphere && x.outOfRange
		);
		if (cylinderRangeOutOfRangeIndex < 0) {
			return retVal.sort((a, b) => a - b).reverse();
		}

		const allowedOutOfRange = product.sphere.cylinderRanges[cylinderRangeOutOfRangeIndex].cylinder;

		for (let i = allowedOutOfRange.min!; i <= allowedOutOfRange.max!; i += product.cylinder.step) {
			retVal.push(i);
		}
	}

	return retVal.sort((a, b) => a - b).reverse();
}

export function SphereCylinderCombinationIsOutOfRange(
	product: Product | undefined,
	sphere: number,
	cylinder: number
) {
	if (!product) {
		return;
	}

	return product.sphere.cylinderRanges.find(
		(x) =>
			x.sphere === sphere &&
			x.cylinder.max !== undefined &&
			cylinder <= x.cylinder.max &&
			x.cylinder.min !== undefined &&
			cylinder >= x.cylinder.min
	)?.outOfRange;
}

export function CanOnlyBeOutOfRangeSphere(product: Product, sphere: number) {
	return (
		product.sphere.cylinderRanges.find((x) => x.sphere === sphere && x.outOfRange) &&
		!product.sphere.cylinderRanges.find((x) => x.sphere === sphere && !x.outOfRange)
	);
}

export function SphereRange(product?: Product, outOfRange = false) {
	if (!product) {
		return [];
	}

	const allSpheres = uniqBy(product.sphere.cylinderRanges, (x) => x.sphere).map((x) => x.sphere);

	const retVal: CylinderRange[] = [];
	allSpheres.forEach((sphere) => {
		const spheres = product?.sphere.cylinderRanges.filter((x) => x.sphere === sphere);

		if (spheres.length > 1) {
			retVal.push(spheres.find((x) => x.outOfRange === outOfRange)!);
		} else if (spheres.length === 1 && (!outOfRange ? !spheres[0].outOfRange : true)) {
			retVal.push(spheres[0]);
		}
	});

	return retVal;
}

export function SphereMeta(product: Product, sphere: number) {
	return SphereRange(product).find((x) => x.sphere === sphere);
}

export class ProductConfiguration {
	productId: number | null = null;
	productConfiguration: ProductConfigurationDto | null = new ProductConfigurationDto();
	engraving: string | null = null;
	engravingData: string | null = null;
	quantity = 1;
	wearerAgeRangeId: number | null = null;
	right: Lens | null = new Lens();
	left: Lens | null = new Lens();
	rxPassData: RxPass | null = new RxPass();

	prism: boolean | null = null;
	surgery: boolean | null = null;

	outOfRangeValuesSelected = false;

	constructor(init?: Partial<ProductConfiguration>) {
		AssignStrict(this, init);

		if (!IsNumber(this.productId)) {
			this.productId = null;
		}

		if (!this.engraving || this.engraving.length < 1) {
			this.engraving = null;
		}

		if (!this.engravingData || this.engravingData.length < 1) {
			this.engravingData = null;
		}

		if (!IsNumber(this.wearerAgeRangeId)) {
			this.wearerAgeRangeId = null;
		}

		if (!this.engravingData || this.engravingData.length < 1) {
			this.engravingData = null;
		}

		if (this.right) {
			this.right = new Lens(this.right);
		}

		if (this.left) {
			this.left = new Lens(this.left);
		}
	}
}

export function AsProduct(value: any) {
	return <ProductConfiguration>value;
}

export class ProductConfigurationDto {
	id: number | null = null;
	name: string | null = null;
	productType: ProductType = null;

	constructor(init?: Partial<ProductConfigurationDto>) {
		AssignStrict(this, init);

		if (!IsNumber(this.id)) {
			this.id = null;
		}

		if (!this.name || this.name.length < 1) {
			this.name = null;
		}

		if (!this.productType || this.productType.length < 1) {
			this.productType = null;
		}
	}
}

export type ProductType = 'individual Rx' | 'reader or protective' | null;

export class Lens {
	sphere: number | null = null;
	cylinder: number | null = null;
	axis: number | null = null;
	addition: number | null = null;

	constructor(init?: Partial<Lens>) {
		AssignStrict(this, init);
	}
}

export interface Product {
	id: number;
	name: string;
	productType?: ProductType;
	sphere: Sphere;
	cylinder?: MinMax;
	axis?: MinMax;
	addition?: MinMax;
}

export interface Sphere {
	step: number;
	cylinderRanges: CylinderRange[];
}

export interface CylinderRange {
	sphere: number;
	cylinder: MinMax;
	outOfRange: boolean;
}

export interface MinMax {
	step?: number;
	min?: number;
	max?: number;
}

export interface LensConfigQuantity {
	quantity: number;
	left: boolean;
	right: boolean;
}

export function OrderFromLocalStorage(storage: Partial<Settings>) {
	const retVal = new Order();

	retVal.id = storage.order_id ?? null;
	retVal.orderId = storage.order_orderId ?? null;
	retVal.shippingCode = storage.order_shippingCode ?? null;
	retVal.type = storage.order_type ?? null;
	retVal.prefillUsedOrderId = storage.order_prefill_used_order_id ?? null;

	retVal.product.productId = storage.order_productId ?? null;
	retVal.product.engraving = storage.order_engraving ?? null;
	retVal.product.engravingData = storage.order_engraving_data ?? null;
	retVal.product.quantity = storage.order_quantity ?? 1;
	retVal.product.wearerAgeRangeId = storage.order_wearerAgeRangeId ?? null;

	retVal.product.prism = storage.order_prism ?? null;
	retVal.product.surgery = storage.order_surgery ?? null;

	retVal.product.right = new Lens();
	retVal.product.right.sphere = storage.lens_right_sphere ?? null;
	retVal.product.right.cylinder = storage.lens_right_cylinder ?? null;
	retVal.product.right.axis = storage.lens_right_axis ?? null;
	retVal.product.right.addition = storage.lens_right_addition ?? null;

	retVal.product.left = new Lens();
	retVal.product.left.sphere = storage.lens_left_sphere ?? null;
	retVal.product.left.cylinder = storage.lens_left_cylinder ?? null;
	retVal.product.left.axis = storage.lens_left_axis ?? null;
	retVal.product.left.addition = storage.lens_left_addition ?? null;

	retVal.product.rxPassData = new RxPass();
	retVal.product.rxPassData.imageData = storage.rxPass_imageData ?? null;
	retVal.product.rxPassData.imageFileType = storage.rxPass_imageFileType ?? null;
	retVal.product.rxPassData.imageBlobUri = storage.rxPass_imageBlobUri ?? null;
	retVal.product.rxPassData.name = storage.rxPass_name ?? null;
	retVal.product.rxPassData.size = storage.rxPass_size ?? null;
	retVal.product.rxPassData.countryCode = storage.rxPass_countryCode ?? null;
	retVal.product.rxPassData.countryStateCode = storage.rxPass_countryStateCode ?? null;
	retVal.product.rxPassData.extension = storage.rxPass_extension ?? null;

	return retVal;
}

export function OrderToLocalStorage(order: Order) {
	const retVal: Partial<Settings> = {};

	retVal.order_id = order.id;
	retVal.order_orderId = order.orderId;
	retVal.order_prefill_used_order_id = order.prefillUsedOrderId;
	retVal.order_shippingCode = order.shippingCode ?? null;
	retVal.order_type = order.type ?? null;

	retVal.order_productId = order.product.productId;
	retVal.order_engraving = order.product.engraving;
	retVal.order_engraving_data = order.product.engravingData;
	retVal.order_quantity = order.product.quantity;
	retVal.order_wearerAgeRangeId = order.product.wearerAgeRangeId;

	retVal.order_prism = order.product.prism;
	retVal.order_surgery = order.product.surgery;

	retVal.lens_right_sphere = order.product.right?.sphere ?? null;
	retVal.lens_right_cylinder = order.product.right?.cylinder ?? null;
	retVal.lens_right_axis = order.product.right?.axis ?? null;
	retVal.lens_right_addition = order.product.right?.addition ?? null;

	retVal.lens_left_sphere = order.product.left?.sphere ?? null;
	retVal.lens_left_cylinder = order.product.left?.cylinder ?? null;
	retVal.lens_left_axis = order.product.left?.axis ?? null;
	retVal.lens_left_addition = order.product.left?.addition ?? null;

	retVal.rxPass_imageData = order.product.rxPassData?.imageData;
	retVal.rxPass_imageFileType = order.product.rxPassData?.imageFileType;
	retVal.rxPass_imageBlobUri = order.product.rxPassData?.imageBlobUri;
	retVal.rxPass_name = order.product.rxPassData?.name;
	retVal.rxPass_size = order.product.rxPassData?.size;
	retVal.rxPass_countryCode = order.product.rxPassData?.countryCode;
	retVal.rxPass_countryStateCode = order.product.rxPassData?.countryStateCode;
	retVal.rxPass_extension = order.product.rxPassData?.extension;

	return retVal;
}

export enum CTRL {
	order_id = 'order_id',
	order_orderId = 'order_orderId',
	order_prefillUsedOrderId = 'order_prefillUsedOrderId',
	order_shippingCode = 'order_shippingCode',
	order_shippingCodeFilter = 'order_shippingCodeFilter',
	order_tcConfirmed = 'order_tcConfirmed',
	order_authConfirmed = 'order_authConfirmed',
	order_type = 'order_type',

	order_productId = 'order_productId',
	order_engraving = 'order_engraving',
	order_quantity = 'order_quantity',
	order_wearerAgeRangeId = 'order_wearerAgeRangeId',

	order_prism = 'order_prism',
	order_surgery = 'order_surgery',

	lens_right_sphere = 'lens_right_sphere',
	lens_right_cylinder = 'lens_right_cylinder',
	lens_right_axis = 'lens_right_axis',
	lens_right_addition = 'lens_right_addition',

	lens_left_sphere = 'lens_left_sphere',
	lens_left_cylinder = 'lens_left_cylinder',
	lens_left_axis = 'lens_left_axis',
	lens_left_addition = 'lens_left_addition',

	rxPass_imageData = 'rxPass_imageData',
	rxPass_imageFileType = 'rxPass_imageFileType',
	rxPass_countryCode = 'rxPass_countryCode',
	rxPass_countryCodeFilter = 'rxPass_countryCodeFilter',
	rxPass_countryStateCode = 'rxPass_countryStateCode',
	rxPass_countryStateCodeFilter = 'rxPass_countryStateCodeFilter',
	rxPass_name = 'rxPass_name',
	rxPass_size = 'rxPass_size',
	rxPass_imageBlobUri = 'rxPass_imageBlobUri',
	rxPass_extension = 'rxPass_extension',
}

export enum CTRL_PART {
	first = 'lens_',
	sphere = '_sphere',
	cylinder = '_cylinder',
	axis = '_axis',
	addition = '_addition',
}

export const RXPASS_EXAMPLES = {
	example1: 'assets/images/rxpass/example-pass-01.png',
};

export type ConfigMethod = 'rxPassData' | 'manualData' | 'automatic' | 'notNeeded' | null;

export const PRODUCT_ATTRIBUTE = {
	ENGRAVING_MAX_CHARS: 10,
	ID_INDIVIDUAL: 1,
};

export const ENGRAVING = {
	previewFrame: 'assets/images/engraving/pre-evt-frame.png',
	fontFamilyText: 'SF Pro Rounded semi-bold',
	fontFamilyEmoji: 'Apple Monochrome Emoji Ind',
	fontSize: 270,
	previewWidth: 250,
	previewHeight: 100,
	previewResolution: 10,
	textHeight: 600,
	maxEngravingChars: 10,
};

export function acceptedEngravingCharCodes(alphanumericOnly = true, emojiOnly = false) {
	// Accepted characters according to Athena's webshop

	let acceptedCharacters: string[] = [];

	if (alphanumericOnly) {
		acceptedCharacters = [...'1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ '];
	} else {
		if (!emojiOnly) {
			acceptedCharacters = [
				...'1234567890qwertzuiop+asdfghjkl#yxcvbnm,.- !"$%&/()=?`QWERTZUIOPASDFGHJKL\'YXCVBNM;:_“”#^',
			];
		}

		acceptedCharacters = acceptedCharacters.concat(
			...'😀😊😆😂🤣😜😬😍😘🥰😎🤩👍✌🤟👊♥💕★✨⚽🏀🏈⚾👾🦄👻🤖🐻🐱👽💀🐯🐉🐵🐶💩🐴🐏🐇🐍🐔🐖🐭🐮',
			...'􀀄􀀅􀀆􀀇􀀈􀀉􀀊􀀋􀀌􀀍􀀎􀀏􀀐􀀑􀀒􀀓􀀔􀀕􀀖􀀗􀀘􀀙􀀚􀀛􀀜􀀝􀀞􀀟􀀠􀀡􀀢􀀣􀀤􀀥􀀦',
			...'􀀧􀀨􀀩􀀪􀀫􀀬􀀭􀀮􀀯􀀰􀀱􀀲􀀳􀀴􀀵􀀶􀀷􀀸􀀹􀀺􀀻􀀼􀀽􀀾􀀿􀁀􀁁􀁂􀁃􀁄􀁅􀁆􀁇􀁈􀁉􀁊􀁋􀓵􀔔􀓶',
			...'􀔕􀓷􀔖􀓸􀔗􀓹􀔘􀓺􀔙􀓻􀔚􀓼􀔛􀓽􀔜􀓾􀔝􀓿􀔞􀔀􀔟􀔁􀔠􀔂􀔡􀔃􀔢􀔄􀔣􀔅􀔤􀔆􀔥􀔇􀔦􀔈􀔧􀔉􀔨􀘠',
			...'􀘡􀚗􀚘􀚙􀚚􀚛􀚜􀚝􀚞􀚟􀚠􀚡􀚢􀚣􀚤􀚥􀚦􀚧􀚨􀚩􀚪􀚫􀚬􀚭􀚮􀚯􀚰􀚱􀚲􀚳􀚴􀚵􀚶􀚷􀚸􀚹􀚺􀚻􀚼'
		);
	}
	const retVal: number[] = [];
	for (let index = 0; index < acceptedCharacters.length; index++) {
		const code = acceptedCharacters[index].codePointAt(0);
		if (code) {
			retVal.push(code);
		}
	}

	return retVal;
}

export function engravingFontFamily(allowEmojis = false) {
	return `${ENGRAVING.fontFamilyText}` + (allowEmojis ? `, ${ENGRAVING.fontFamilyEmoji}` : '');
}
