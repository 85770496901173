import { ROUTE_CONFIG } from 'src/app/app-routing.module';
import { AuthService } from 'src/app/common/auth/auth.service';
import { OpenExternal } from 'src/app/helper/common';
import { CorporateCardContent } from 'src/app/shared/corporate-card/corporate-card';
import { Build } from 'src/environments/build';
import { Environment } from 'src/environments/environment';
import { App } from 'src/environments/shared';

import { Component, Input } from '@angular/core';

@Component({
	selector: 'vis-splash',
	templateUrl: './splash.component.html',
	styleUrls: ['./splash.component.scss'],
})
export class SplashComponent {
	app = App;
	env = Environment;
	build = Build;
	routes = ROUTE_CONFIG;
	openExt = OpenExternal;

	@Input()
	userHasAccessRights: boolean = true;

	contents: { [key: string]: CorporateCardContent } = {
		prescription: {
			icon: '/assets/images/corporate/Glasses icon.png',
			url: this.routes.product.path,
			text: 'Enter your eyeglasses prescription values <span class=underline>here</span>',
		},
		zeiss: {
			preHeadline: 'ABOUT ZEISS',
			headline: 'The Carl Zeiss Foundation',
			url: 'https://www.zeiss.com/corporate/int/about-zeiss/carl-zeiss-foundation.html',
			text:
				'is the sole shareholder of <span class=no-wrap>Carl Zeiss AG</span>. This special ownership structure ensures stability and long-term-orientation.<br>A strong foundation for a strong future.',
		},
		response: {
			preHeadline: 'Responsibility at ZEISS',
			headline: 'Globally Carbon-Neutral by 2025',
			url: 'https://www.zeiss.com/corporate/int/about-zeiss/responsibility.html',
			image: '/assets/images/corporate/Responsibility picture.png',
			text:
				'is the sole shareholder of <span class=no-wrap>Carl Zeiss AG</span>. This special ownership structure ensures stability and long-term-orientation.<br>A strong foundation for a strong future.',
		},
		vis: {
			preHeadline: 'Responsibility at ZEISS',
			headline:
				'<a class=descrete href=https://www.zeiss.com/vision-care/int/home.html?vaURL=www.zeiss.com/vision-care target=_blank>ZEISS VISION CARE</a> & <a class=descrete href=https://www.zeiss.com/meditec/int/home.html target=_blank>MEDICAL TECHNOLOGY</a>',
			url: 'https://www.zeiss.com/vision-care/int/home.html?vaURL=www.zeiss.com/vision-care',
			image: '/assets/images/corporate/VIS picture.jpg',
			text: 'Unrivaled in the understanding of the eye and dynamic vision.',
		},
		smt: {
			url: 'https://www.zeiss.com/semiconductor-manufacturing-technology/home.html',
			headline: 'ZEISS SEMI-CONDUCTOR MANUFACTURING TECHNOLOGY',
			text: 'Enabling the next generation of microchips with EUV technology.',
			image: '/assets/images/corporate/SMT Picture.png',
		},
		iqs: {
			url: 'https://www.zeiss.com/metrology/home.html?vaURL=www.zeiss.com/imtl',
			headline:
				'<a class=descrete href=https://www.zeiss.com/metrology/home.html?vaURL=www.zeiss.com/imtl target=_blank>ZEISS INDUSTRIAL QUALITY SOLUTIONS</a>',
			text:
				'F1 racing car engineers use <span class=no-wrap>ZEISS measuring machines</span> and software to ensure optimum engine performance.',
			image: '/assets/images/corporate/IQS picture.jpg',
		},
		mic: {
			url: 'https://www.zeiss.com/microscopy/int/home.html',
			headline: 'ZEISS MICROSCOPY',
			text:
				'Helping mankind change the world.<br>30 Nobel laureates used <span class=no-wrap>ZEISS microscopes</span> to achieve their breakthroughs.',
			image: '/assets/images/corporate/MICRO Picture.jpg',
		},
	};

	constructor(public auth: AuthService) {}
}
