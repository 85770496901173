import '@zeiss/zui';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { App, CustomIcons } from 'src/environments/shared';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { AfterViewInit, Component, HostListener, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { registerTheme, registerTypefaces, setAppTheme, themeBase } from '@zeiss/zui-theme-base';
import { themeLight } from '@zeiss/zui-theme-light';

import { routerAnimation } from './animations/animations';
import { OrderApiService } from './common/api/order-api.service';
import { ProductApiService } from './common/api/product-api.service';
import { WhitelistApiService } from './common/api/whitelist-api.service';
import { DataExtended } from './common/app';
import { AuthService } from './common/auth/auth.service';
import { FeatureFlagService } from './common/feature-flag/feature-flag.service';
import { ExtendedRouteData } from './common/router/route';
import { SettingsService } from './common/settings/settings.service';
import { TutorialService } from './common/tutorial/tutorial.service';

registerLocaleData(localeEn, 'en');

@Component({
	selector: 'vis-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [routerAnimation],
})
export class AppComponent implements AfterViewInit, OnDestroy {
	private destroy$ = new Subject();

	@HostListener('window:resize', []) public resize() {
		this.tutorial.resize$.next();
	}

	private userRights$ = this.auth.Authenthicated.pipe(
		mergeMap(() => this.whitelistApi.getUserRoles(this.auth.UserZeissId!.ZeissIdBase.accountId))
	);

	private userHasRole$: Observable<boolean> = combineLatest([
		this.userRights$,
		this._flags.EnabledRoles,
	]).pipe(
		map((rights) => {
			const userRights = rights[0];
			const enabledRights = rights[1];
			const found = userRights.some((r) => enabledRights.includes(r));
			return found;
		})
	);

	public hasRoleToAccess$: Observable<boolean> = this.userHasRole$.pipe(
		tap((res) => console.log('[OBSERVABLE] hasRoleToAccess$', res))
	);

	constructor(
		private _matIconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer,
		private _router: Router,
		private _orderApi: OrderApiService,
		private _productApi: ProductApiService,
		private _dialog: MatDialog,
		private _flags: FeatureFlagService,
		public tutorial: TutorialService,
		public settings: SettingsService,
		public auth: AuthService,
		private whitelistApi: WhitelistApiService
	) {
		this.init();
		this.manageWindowTitle();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit() {
		this._orderApi.BackendFailure.pipe(takeUntil(this.destroy$)).subscribe((x) => {
			if (x) {
				this._dialog.closeAll();
			}
		});
		this._productApi.BackendFailure.pipe(takeUntil(this.destroy$)).subscribe((x) => {
			if (x) {
				this._dialog.closeAll();
			}
		});

		this.auth.Authenthicated.pipe(
			takeUntil(this.destroy$),
			filter((authenticated) => authenticated),
			mergeMap(() =>
				combineLatest([
					this._productApi.FetchProducts(),
					this._orderApi.FetchShippingLocations(),
					this._orderApi.FetchWearerAgeRanges(),
					this._orderApi.FetchCountries(),
					this._orderApi.FetchUsStates(),
				])
			)
		).subscribe();
	}

	init() {
		this.registerCustomIcons();
		registerTheme(themeBase);
		registerTheme(themeLight);
		registerTypefaces('/fonts');
		setAppTheme('light');
	}

	manageWindowTitle() {
		document.title = `${App.Name1} ${App.Name2}`;
		this._router.events
			.pipe(
				takeUntil(this.destroy$),
				filter((event) => event instanceof ActivationEnd),
				map((event: any) => {
					return (event as ActivationEnd).snapshot.data;
				})
			)
			.subscribe((data: ExtendedRouteData) => {
				document.title = data.title ? `${App.Name1} - ${data.title}` : `${App.Name1} ${App.Name2}`;
			});
	}

	registerCustomIcons() {
		CustomIcons.forEach((icon: { Label: string; Path: string }) => {
			this._matIconRegistry.addSvgIcon(
				icon.Label,
				this._domSanitizer.bypassSecurityTrustResourceUrl(icon.Path)
			);
		});
	}

	public getRouteAnimation(outlet: RouterOutlet) {
		const hierarchy = (<DataExtended>outlet.activatedRouteData).animationHierarchy;
		return !hierarchy ? -1 : hierarchy;
	}

	tutorialDismiss() {
		this.tutorial.tutorialState$.subscribe((tutorialState) => {
			if (tutorialState === 'OPEN') {
				this.tutorial.clickOutside$.next();
			}
		});
	}
}
