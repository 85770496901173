import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FormErrorComponent } from './form-error.component';

@NgModule({
	declarations: [FormErrorComponent],
	imports: [CommonModule],
	exports: [FormErrorComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormErrorModule {}
