import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
	transform(value?: string, maxLength = 20) {
		if (maxLength < 5 || !value || value.trim().length <= maxLength) {
			return value;
		}

		maxLength -= 1;

		const partsLength = maxLength / 2;

		return `${value.substring(0, partsLength).trim()}…${value
			.substring(value.length - partsLength)
			.trim()}`;
	}
}
