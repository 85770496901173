import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AgoPipe } from './ago.pipe';

@NgModule({
	declarations: [AgoPipe],
	imports: [CommonModule],
	exports: [AgoPipe],
	providers: [AgoPipe],
})
export class AgoModule {}
