import { OrderCancelConfirmComponent } from 'src/app/areas/order/order-cancel-confirm/order-cancel-confirm.component';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'vis-confirm-close',
	templateUrl: './confirm-close.component.html',
	styleUrls: ['./confirm-close.component.scss'],
})
export class ConfirmCloseComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<OrderCancelConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {}
}
