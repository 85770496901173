<zui-popover [busy]="busy">
	<zui-section-divider>
		<span>Your profile</span>
	</zui-section-divider>

	<div style="padding: var(--zui-gu)">
		<vis-item label="Account ID">
			<span>{{ auth.UserZeissId?.ZeissIdBase?.accountId }}</span>
		</vis-item>

		<vis-item label="Email">
			<p class="truncate">{{ auth.UserZeissId?.ZeissIdBase?.eMail }}</p>
		</vis-item>

		<vis-item label="Name">
			<p>
				{{ auth.UserZeissId?.ZeissIdBase?.firstName }} {{ auth.UserZeissId?.ZeissIdBase?.lastName }}
			</p>
		</vis-item>

		<vis-item label="Reset settings">
			<zui-interactive-icon
				(click)="busy = true; settings.ResetToDefault()"
				[attr.color]="appColors.error"
				visTooltip="Reset your settings"
			>
				<zui-icon-undo></zui-icon-undo>
			</zui-interactive-icon>
		</vis-item>

		<vis-item label="Sign out">
			<zui-interactive-icon
				(click)="signOut()"
				[attr.color]="appColors.error"
				visTooltip="Sign out"
			>
				<zui-icon-logout></zui-icon-logout>
			</zui-interactive-icon>
		</vis-item>
	</div>

	<zui-section-divider>
		<span>{{ app.Name1 }} {{ app.Name2 }}</span>
	</zui-section-divider>

	<div style="padding: var(--zui-gu)">
		<p>
			<b>{{ app.Name1 }}</b> {{ app.Name2 }} {{ build.appVersion }}
			<ng-container *ngIf="envDisplay">
				●
				<span [attr.color]="appColors.info">{{ envDisplay }}</span>
				<span *ngIf="!envDisplay">● #{{ build.lastCommit }}</span>
			</ng-container>
		</p>

		<p *ngIf="envDisplay" style="word-break: break-word">
			<a style="text-decoration: none" target="_blank" href="{{ build.lastCommitUrl }}">
				#{{ build.lastCommit }}
			</a>
			●
			{{ build.lastCommitTime | date: 'YYYY/MM/dd HH:mm' }}
			●
			<span [innerHtml]="build.lastCommitMessage | shorten: 100"></span>
		</p>

		<p class="version-text">
			<span *ngIf="envDisplay">
				Made with <span [attr.color]="appColors.info">♥︎</span> by VIS-BIT of
			</span>
			Carl Zeiss Vision International GmbH
		</p>
	</div>
</zui-popover>
