export const LogPre = '[FEATURE]';

export type FeatureFlags = {
	EVT_Frontend_Allow_Order_Placement: FeatureFlagStatus;
	EVT_Frontend_Engraving_Allow_Emojis: FeatureFlagStatus;
};

export type FeatureFlagStatus = {
	enabled: boolean;
	description?: string;
};

export const ROLES_ALLOWED_CONFIG = 'EVT_PortalAPI:Roles:Allowed';
