import * as dayjs from 'dayjs';

import { LogLevel } from '../logger/logger';

export class Toast {
	title? = 'Unknown';
	message?: string;
	level? = LogLevel.info;
	timestamp?: dayjs.Dayjs;
	duration? = 5000;
	willDisappear? = false;
	secondsLeft? = 0;

	constructor(init: Toast) {
		if (init.title) {
			this.title = init.title;
		}
		if (init.message) {
			this.message = init.message;
		}
		if (init.level || init.level === 0) {
			this.level = init.level;
		}
		if (init.duration) {
			this.duration = init.duration;
		}

		this.timestamp = dayjs();
	}
}
