import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ROUTE_CONFIG } from 'src/app/app-routing.module';
import { BuiltPhase, Order } from 'src/app/areas/order/order';
import { Environment } from 'src/environments/environment';
import { OrderApiService } from '../api/order-api.service';
import { WhitelistApiService } from '../api/whitelist-api.service';
import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { SettingsService } from '../settings/settings.service';
import { ZeissIdClaims } from './auth';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private _userZeissId?: ZeissIdClaims;
	private _sessionTimeLeft = '';

	private _authenthicationCompleted$ = new ReplaySubject<boolean>();
	private _authenticated$ = new ReplaySubject<boolean>();
	private _canManageWhitelist$ = new BehaviorSubject<boolean>(false);
	private _userRights$ = new ReplaySubject<number[]>();

	private _busy = true;

	private _config: AuthConfig = {
		issuer: Environment.AuthConfig.issuer,
		strictDiscoveryDocumentValidation: false,
		redirectUri: window.location.href,
		clientId: Environment.AuthConfig.clientId,
		responseType: 'code',
		fallbackAccessTokenExpirationTimeInSec: 3600,
		scope: 'openid offline_access profile',
		showDebugInformation: true,
	};

	constructor(
		private _oAuth: OAuthService,
		private _logger: LoggerService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _orderApi: OrderApiService,
		private _settings: SettingsService,
		private whitelistApi: WhitelistApiService
	) {
		this._oAuth.events.subscribe((x) => {
			if (x.type === 'token_received') {
				Environment.API.Headers['Authorization'] = 'Bearer '.concat(this._oAuth.getIdToken());
			}

			if (x.type === 'token_expires' && !this._oAuth.getIdToken()) {
				this._authenticated$.next(false);
				this._logger.Log(`Your session expired. Please reload this page.`, LogCat.auth, {
					level: LogLevel.error,
					showToast: true,
				});
			}
		});

		this.init();
	}

	set Busy(value: boolean) {
		this._busy = value;
	}

	get Busy() {
		return this._busy;
	}

	private init(): void {
		this._oAuth.configure(this._config);

		this._logger.Log(`checking auth state`, LogCat.auth);

		this._oAuth.loadDiscoveryDocument(Environment.AuthConfig.discoveryDocumentUrl).then(
			() => {
				this._oAuth.setupAutomaticSilentRefresh();

				this._oAuth.tryLoginCodeFlow().then(
					() => {
						if (!this._oAuth.hasValidIdToken()) {
							this._logger.Log(
								`Not authenticated yet. Initializing Codeflow: redirecting to ZEISS ID login page.`,
								LogCat.auth
							);
							setTimeout(() => {
								this.Busy = false;
								this._settings.ClearStorage(); // Remove users locally stored data
								this._oAuth.initCodeFlow();
							}, 1000);
						} else {
							this._initAfterSignIn();
						}
					},
					(e) => {
						this._authenthicationCompleted$.next(true);
						this._authenticated$.next(false);
						this._settings.ClearStorage(); // Remove users locally stored data
						this._logger.Log(`Auth error: ${e}`, LogCat.auth);
						this.Busy = false;
					}
				);
			},
			(e) => {
				this._authenthicationCompleted$.next(true);
				this._authenticated$.next(false);
				this._logger.Log(`Authorization service error. Please try again later.`, LogCat.auth, {
					level: LogLevel.error,
					showToast: true,
				});
				this.Busy = false;
			}
		);
	}

	SignIn() {
		this._oAuth.initCodeFlow();
	}

	private _initAfterSignIn() {
		Environment.API.Headers['Authorization'] = 'Bearer '.concat(this._oAuth.getIdToken());
		this._userZeissId = this._processZeissIdClaims(this._oAuth.getIdentityClaims());

		this._updateSessionTime();

		this._logger.Log(`Authenticated via ZEISS ID`, LogCat.auth, {
			level: LogLevel.success,
			aiData: {
				'parsed user': this.UserZeissId,
				'session duration left': `${(
					(this._oAuth.getIdTokenExpiration() - Date.now()) /
					1000 /
					60
				).toFixed(0)} min`,
			},
		});

		combineLatest([
			this._orderApi.FetchOrders(),
			this._orderApi.fetchBuiltPhases(),
			this._orderApi.fetchCurrentBuiltPhase(),
			this.whitelistApi.getWhitelistRight(this.UserZeissId!.ZeissIdBase.accountId),
		])
			.pipe(
				finalize(() => {
					this._authenticated$.next(true);
					this.Busy = false;
				})
			)
			.subscribe((data: [Order[], BuiltPhase[], BuiltPhase, boolean]) => {
				this._canManageWhitelist$.next(data[3]);
				if (data[0].length > 0) {
					this._logger.Log(`found ${data[0].length} orders`, LogCat.auth, {
						aiData: {
							orders: data[0],
						},
					});

					if (this._orderApi.ActiveOrders.length > 0) {
						this._logger.Log(
							`found active order, routing to ${ROUTE_CONFIG.orders.path}`,
							LogCat.auth,
							{
								aiData: {
									orders: data[0],
								},
							}
						);
						this._router.navigate([ROUTE_CONFIG.orders.path]);
					}
				}
			});
		this._orderApi.calcNextBuiltPhase().toPromise();

		this._cleanOauthLeftovers();
	}

	private _cleanOauthLeftovers() {
		const paramMap = this._activatedRoute.snapshot.queryParamMap;
		if (this._activatedRoute.snapshot.fragment || paramMap.has('code') || paramMap.has('state')) {
			this._router.navigate([], {
				relativeTo: this._activatedRoute,
				fragment: undefined,
				queryParams: { code: null, state: null },
				queryParamsHandling: 'merge',
			});
		}
	}

	private _processZeissIdClaims(raw: object) {
		try {
			const retVal = { ...(raw as ZeissIdClaims) };
			retVal.ZeissIdAgreements = JSON.parse(retVal.ZeissIdAgreements.toString());
			retVal.ZeissIdApplication = JSON.parse(retVal.ZeissIdApplication.toString());
			retVal.ZeissIdBase = JSON.parse(retVal.ZeissIdBase.toString());
			retVal.ZeissIdContact = JSON.parse(retVal.ZeissIdContact.toString());
			retVal.ZeissIdCustomFields = JSON.parse(retVal.ZeissIdCustomFields.toString());
			retVal.ZeissIdOrganisation = JSON.parse(retVal.ZeissIdOrganisation.toString());

			return retVal;
		} catch (error) {
			this._logger.Log(`claims could not be parsed`, LogCat.auth, {
				level: LogLevel.error,
				aiData: {
					'raw claims': raw,
				},
			});
			return undefined;
		}
	}

	private _updateSessionTime() {
		setInterval(
			() =>
				`${(this._sessionTimeLeft = (
					(this._oAuth.getIdTokenExpiration() - Date.now()) /
					1000 /
					60
				).toFixed(0))} min`,
			1000
		);
	}

	SignOut() {
		this._logger.Log(`user logging out manually`, LogCat.auth, {
			aiData: {
				user: this.UserZeissId,
			},
		});
		this._oAuth.logOut();
	}

	get UserZeissId() {
		return this._userZeissId;
	}

	get SessionTimeLeft() {
		return this._sessionTimeLeft;
	}

	get AuthenthicationCompleted() {
		return this._authenthicationCompleted$;
	}

	get Authenthicated() {
		return this._authenticated$;
	}

	get canManageWhitelist() {
		return this._canManageWhitelist$;
	}

	get userRights$() {
		return this._userRights$;
	}
}
