<p class="item-label" [class.hide]="fullWidth && !label" [class.fixed]="fixed">
	{{ label }}
</p>
<div class="vis-flex-spacer"></div>
<div
	class="item-content"
	[class.fixed]="fixed"
	[class.item-align-left]="align === 'left'"
	[class.full-width]="fullWidth"
>
	<ng-content></ng-content>
	<div class="bottom-placeholder" *ngIf="!errors && !hint"></div>
	<vis-form-error *ngIf="errors" [errors]="errors"></vis-form-error>
	<span class="hint" *ngIf="!errors && hint" [innerHtml]="hint"></span>
</div>
