import { fromEvent, merge, of, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { VisTooltipComponent } from './tooltip.component';

@Directive({ selector: '[visTooltip]' })
export class VisTooltipDirective implements OnInit, OnDestroy {
	@Input('visTooltip') text?: string | null;
	@Input('visTooltipDelay') delay = 200;
	private overlayRef: OverlayRef;
	private destroy$ = new Subject();

	constructor(
		private overlayPositionBuilder: OverlayPositionBuilder,
		private elementRef: ElementRef,
		private overlay: Overlay,
		private router: Router
	) {
		this.overlayRef = this.overlay.create({});
	}

	ngOnInit() {
		const hide$ = fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(map(() => false));
		const click$ = fromEvent(this.elementRef.nativeElement, 'click').pipe(map(() => false));
		const show$ = fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(
			map(() => {
				if (this.text) return true;
				return false;
			})
		);

		merge(hide$, show$, click$)
			.pipe(
				takeUntil(this.destroy$),
				switchMap((show) => {
					if (show) {
						return of(true).pipe(delay(this.delay));
					}
					return of(false);
				})
			)
			.subscribe((show) => {
				if (show) {
					const portal = new ComponentPortal(VisTooltipComponent);
					const compRef = this.overlayRef.attach(portal);
					compRef.instance.text = this.text ?? '';
				} else {
					this.overlayRef.detach();
				}
			});

		const positionStrategy = this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions([
				{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
					offsetY: -5,
				},
			]);

		this.router.events
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.overlayRef.detach())
			)
			.subscribe();

		this.overlayRef = this.overlay.create({ positionStrategy });
	}

	ngOnDestroy() {
		this.overlayRef.detach();
	}
}
