import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BusyDirective } from './busy.directive';

@NgModule({
	declarations: [BusyDirective],
	imports: [CommonModule],
	exports: [BusyDirective],
	providers: [BusyDirective],
})
export class BusyModule {}
