import { Injectable } from '@angular/core';
import {
	AppConfigurationClient,
	FeatureFlagValue,
	GetConfigurationSettingResponse,
} from '@azure/app-configuration';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Environment } from 'src/environments/environment';
import { FeatureFlags, LogPre, ROLES_ALLOWED_CONFIG } from './feature-flag';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private readonly _featureFlagPrefix = '.appconfig.featureflag/';
	private _flags = new BehaviorSubject<FeatureFlags | undefined>(undefined);
	private _enabledRoles: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

	constructor() {
		this._init();
		this.FeatureFlags.subscribe((x) => console.debug(LogPre, 'FeatureFlags updated', x));
	}

	private _init() {
		const client = new AppConfigurationClient(Environment.AzureAppConfig.ConnectionString);

		const flags: FeatureFlags = {
			EVT_Frontend_Allow_Order_Placement: {
				enabled: false,
			},
			EVT_Frontend_Engraving_Allow_Emojis: {
				enabled: false,
			},
		};

		const requests: Promise<GetConfigurationSettingResponse>[] = [];
		for (const key in flags) {
			if (Object.prototype.hasOwnProperty.call(flags, key)) {
				const req = client.getConfigurationSetting(
					{ key: this._featureFlagPrefix + key },
					{
						requestOptions: {
							customHeaders: { 'Cache-Control': 'no-cache' },
						},
					}
				);
				requests.push(req);
			}
		}

		forkJoin(requests).subscribe(
			(flagSettings) => {
				for (const _key in flags) {
					const key = _key as keyof FeatureFlags;
					if (Object.prototype.hasOwnProperty.call(flags, key)) {
						const rawValue = flagSettings.find(
							(flagSetting) => flagSetting.key.replace(this._featureFlagPrefix, '') === key
						)?.value;
						let parsedValue: FeatureFlagValue = rawValue
							? JSON.parse(rawValue)
							: { enabled: false };
						flags[key] = {
							enabled: parsedValue.enabled,
							description: parsedValue.description,
						};
					}
				}
				this._flags.next(flags);
			},
			(err) => console.warn(LogPre, 'Could not get feature flags.')
		);

		// Reads all allowed roles from the app configuration and stores them into the behavior subject
		client
			.getConfigurationSetting({ key: ROLES_ALLOWED_CONFIG })
			.then((setting: GetConfigurationSettingResponse) => {
				const val = setting.value;
				if (!val) return;

				const vals = val.split(',');
				const normalizedVals: number[] = [];
				vals.forEach((v) => {
					normalizedVals.push(parseInt(v));
				});
				this._enabledRoles.next(normalizedVals);
			})
			.catch((e) => console.warn(LogPre, 'Could not get configuration setting.'));
	}

	get FeatureFlags() {
		return this._flags;
	}

	get EnabledRoles() {
		return this._enabledRoles;
	}
}
