import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
	BuiltPhase,
	Country,
	Order,
	OrderStatusEnum,
	ShippingLocation,
	WearerAgeRange,
	orderHasOutOfRangeValues,
} from 'src/app/areas/order/order';
import { ApiService } from 'src/app/common/api/api.service';
import { LogCat, LogLevel } from 'src/app/common/logger/logger';
import { LoggerService } from 'src/app/common/logger/logger.service';
import { Environment } from 'src/environments/environment';
import { ApiShared, ApiVersion } from 'src/environments/shared';
import { MINIMUM_CALL_DURATION } from './api';
import { ProductApiService } from './product-api.service';

@Injectable({
	providedIn: 'root',
})
export class OrderApiService extends ApiService {
	_orders$ = new BehaviorSubject<Order[]>([]);
	_shippingLocations$ = new BehaviorSubject<ShippingLocation[]>([]);
	_wearerAgeRanges$ = new BehaviorSubject<WearerAgeRange[]>([]);
	_countries$ = new BehaviorSubject<Country[]>([]);
	_usStates$ = new BehaviorSubject<Country[]>([]);
	private builtPhases$ = new BehaviorSubject<BuiltPhase[]>([]);
	private currentBuiltPhase$ = new BehaviorSubject<BuiltPhase | null>(null);
	private nextBuiltPhase$ = new BehaviorSubject<BuiltPhase | null>(null);
	allowPlacingOrder$ = combineLatest([this.currentBuiltPhase$, this._orders$]).pipe(
		map((data: [BuiltPhase | null, Order[]]) => {
			// if no current built phase provided, don't allow placing order
			if (!data[0]) return false;
			// if no order found, allow placing order
			if (data[1].length < 1) return true;
			const order = data[1][0];
			// if order has no built phase, reject placing order
			if (!order.builtPhase && order.status && order.status.id < OrderStatusEnum.ORDER_PLACED)
				return false;
			// if order has built phase and it's current built phase, reject order
			if (
				order.builtPhase?.dateFromUTCTs === data[0].dateFromUTCTs &&
				order.builtPhase?.dateToUTCTs === data[0].dateToUTCTs &&
				order.builtPhase?.name === data[0].name
			)
				return false;
			// everything else allow
			return true;
		})
	);

	constructor(
		public _http: HttpClient,
		public _logger: LoggerService,
		private _apiProduct: ProductApiService
	) {
		super(_http, _logger);
	}

	public FetchOrders() {
		const id = this.StartRequest('getOrders');

		const retVal = this._http.get<Order[]>(
			Environment.API.Path + ApiVersion['v1.0'] + ApiShared.Paths.Order,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					resp = resp.sort((a, b) => +(b.orderId ?? 0) - +(a.orderId ?? 0));
					this._orders$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get Orders() {
		return this._orders$;
	}

	public get ActiveOrders() {
		return this.Orders.getValue().filter(
			(x) => x.status && x.status.id < OrderStatusEnum.ORDER_PLACED
		);
	}

	public FetchShippingLocations() {
		const id = this.StartRequest('getShippingLocations');

		const retVal = this._http.get<ShippingLocation[]>(
			Environment.API.Path + ApiShared.Paths.ShippingLocation,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					resp = resp.sort((a, b) => (a.sorting ?? 0) - (b.sorting ?? 0));
					this._shippingLocations$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get ShippingLocations() {
		return this._shippingLocations$;
	}

	public ShippingLocationsByOrder(orderId?: string | null) {
		const locations = this.ShippingLocations.getValue();

		if (!orderId) {
			return locations;
		}

		const orders = this.Orders.getValue();
		const index = orders.findIndex((x) => x.orderId === orderId);
		if (index < 0 || !orders[index].shippingCode) {
			return locations;
		}

		if (locations.findIndex((x) => x.code === orders[index].shippingCode) >= 0) {
			return locations;
		}

		return [
			...locations,
			new ShippingLocation({
				code: orders[index].shippingCode!,
				description: orders[index].shippingText ?? 'n.a.',
				sorting: -1,
			}),
		].sort((a, b) => (a.sorting ?? 0) - (b.sorting ?? 0));
	}

	public FetchWearerAgeRanges() {
		const id = this.StartRequest('getWearerAgeRanges');

		const retVal = this._http.get<WearerAgeRange[]>(
			Environment.API.Path + ApiShared.Paths.WearerAgeRange,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					this._wearerAgeRanges$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public fetchBuiltPhases() {
		const id = this.StartRequest('getBuiltPhases');

		const retVal = this._http.get<BuiltPhase[]>(
			Environment.API.Path + '/v1.0' + ApiShared.Paths.BuiltPhases,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					this.builtPhases$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get builtPhases() {
		return this.builtPhases$;
	}

	public fetchCurrentBuiltPhase() {
		const id = this.StartRequest('getCurrentBuiltPhase');

		const retVal = this._http.get<BuiltPhase>(
			Environment.API.Path + '/v1.0' + ApiShared.Paths.BuiltPhaseCurrent,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			map((resp) => {
				this.currentBuiltPhase$.next(resp);
				return resp;
			}),
			tap(
				() => {},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get currentBuiltPhase() {
		return this.currentBuiltPhase$;
	}

	public calcNextBuiltPhase() {
		return combineLatest([
			timer(MINIMUM_CALL_DURATION),
			this.builtPhases,
			this.currentBuiltPhase,
		]).pipe(
			map((x: [any, BuiltPhase[], BuiltPhase | null]) => {
				if (!x[2]) {
					return this.nextBuiltPhase$.next(null);
				}

				const futurePhases = x[1]
					.filter((phase) => phase.dateFromUTCTs > x[2]!.dateToUTCTs)
					.sort((a, b) => a.dateFromUTCTs - b.dateFromUTCTs);

				this.nextBuiltPhase$.next(futurePhases.length < 1 ? null : futurePhases[0]);
			})
		);
	}

	public get nextBuiltPhase() {
		return this.nextBuiltPhase$;
	}

	public get WearerAgeRange() {
		return this._wearerAgeRanges$;
	}

	public FetchCountries() {
		const id = this.StartRequest('getCountries');

		const retVal = this._http.get<Country[]>(Environment.API.Path + ApiShared.Paths.Countries, {
			params: this.Params,
			headers: this.Headers,
		});

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					this._countries$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get Countries() {
		return this._countries$;
	}

	public FetchUsStates() {
		const id = this.StartRequest('getUsStates');

		const retVal = this._http.get<Country[]>(
			Environment.API.Path + ApiShared.Paths.Countries + '/us/state',
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					this._usStates$.next(resp);
					return resp;
				},
				(e) => this.LogError(e, LogCat.order, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get UsStates() {
		return this._usStates$;
	}

	public CreateOrder(order: Order) {
		const id = this.StartRequest('createOrder');

		order.product.outOfRangeValuesSelected =
			orderHasOutOfRangeValues(this._apiProduct.Products.getValue(), order) ?? false;

		const retVal = this._http.post<Order>(
			Environment.API.Path + ApiVersion['v1.0'] + ApiShared.Paths.Order,
			order,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION * 2), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => resp,
				(e) => {
					this._logger.Log(`Your order could not be placed. Please try again.`, LogCat.order, {
						level: LogLevel.error,
						showToast: true,
					});
				}
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public UpdateOrder(
		order: Order,
		rxRestart?: boolean,
		imageChange?: boolean,
		engravingChange?: boolean
	) {
		const id = this.StartRequest('createOrder');

		order.product.outOfRangeValuesSelected =
			orderHasOutOfRangeValues(this._apiProduct.Products.getValue(), order) ?? false;

		let params = this.Params;
		// rxr is always true if image data has been changed
		if (imageChange || rxRestart) {
			params = params.append('rxr', 1);
		}
		if (imageChange && order.type === 'rxPassData') {
			params = params.append('ic', 1);
		}
		if (engravingChange) {
			params = params.append('ec', 1);
		}

		const retVal = this._http.patch<Order>(
			Environment.API.Path + ApiVersion['v1.0'] + ApiShared.Paths.Order + `/${order.id}`,
			order,
			{
				params: params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION * 2), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => resp,
				(e) => {
					this._logger.Log(`Your order could not be updated. Please try again.`, LogCat.order, {
						level: LogLevel.error,
						showToast: true,
					});
				}
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public CancelOrder(rowId: string) {
		const id = this.StartRequest('cancelOrder');

		const retVal = this._http.delete<Order>(
			Environment.API.Path + ApiVersion['v1.0'] + ApiShared.Paths.Order + `/${rowId}`,
			{
				params: this.Params,
				headers: this.Headers,
			}
		);

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => resp,
				(e) => {
					this.LogError(e, LogCat.order, true);
				}
			),
			finalize(() => this.EndRequest(id))
		);
	}
}
