import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriticalInfoComponent } from './critical-info.component';

@NgModule({
	declarations: [CriticalInfoComponent],
	imports: [CommonModule],
	exports: [CriticalInfoComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CriticalInfoModule {}
