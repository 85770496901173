import { NgModule } from '@angular/core';

import { AgoModule } from './ago/ago.module';
import { AttributeModule } from './attribute/attribute.module';
import { BusyModule } from './busy/busy.module';
import { CorporateCardModule } from './corporate-card/corporate-card.module';
import { EmojiInputModule } from './emoji-input/emoji-input.module';
import { CriticalInfoModule } from './critical-info/critical-info.module';
import { FormErrorModule } from './form-validation/form-error.module';
import { HrefExternalModule } from './href-external/href-external.module';
import { IconModule } from './icon/icon.module';
import { ItemModule } from './item/item.module';
import { ShortenModule } from './shorten/shorten.module';
import { TextToImageModule } from './text-to-image/text-to-image.module';
import { VisTooltipModule } from './tooltip/tooltip.module';
import { AccessorsModule } from './value-accessors/accessors.module';

@NgModule({
	exports: [
		FormErrorModule,
		BusyModule,
		IconModule,
		VisTooltipModule,
		ShortenModule,
		ItemModule,
		AccessorsModule,
		AttributeModule,
		AgoModule,
		HrefExternalModule,
		CorporateCardModule,
		TextToImageModule,
		EmojiInputModule,
		CriticalInfoModule,
	],
})
export class GlobalImportsModule {}
