import { BehaviorSubject } from 'rxjs';
import { Environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { OngoingRequest } from './api';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	protected _ongoingRequests: OngoingRequest[] = [];
	protected _backendFailure$ = new BehaviorSubject<boolean>(false);

	constructor(protected _http: HttpClient, protected _logger: LoggerService) {}

	protected LogError(
		error: HttpErrorResponse | string,
		category: LogCat,
		silent = false,
		critical = false
	) {
		let msg = '';
		if (typeof error === 'string') {
			msg = error;
		} else if (error.error?.apiStatus) {
			msg = `${error.error.apiStatus}`;
		} else {
			msg = `<b>${error.status}</b>: ${error.statusText}`;
		}

		if (!silent) {
			this._logger.Log(msg, category, { level: LogLevel.error, showToast: true });
		}

		if (critical) {
			this.BackendFailure.next(true);
		}
	}

	protected StartRequest(type?: string) {
		const retVal = { id: uuidv4(), type: type ?? '' };
		this._ongoingRequests.push(retVal);
		return retVal.id;
	}

	protected EndRequest(uuid: string) {
		const index = this._ongoingRequests.findIndex((x) => x.id === uuid);
		if (index > -1) {
			this._ongoingRequests.splice(index, 1);
		}
	}

	Busy(types: string[] = []) {
		if (types.length > 0) {
			types = types.map((x) => x.toLowerCase());
			return this._ongoingRequests.filter((x) => types.includes(x.type.toLowerCase())).length > 0;
		}
		return this._ongoingRequests.length > 0;
	}

	get BackendFailure() {
		return this._backendFailure$;
	}

	protected get Params() {
		let retVal = new HttpParams();
		for (const key in Environment.API.Params) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Params, key)) {
				retVal = retVal.append(key, (Environment.API.Params as { [key: string]: string })[key]);
			}
		}
		return retVal;
	}

	protected get Headers() {
		let retVal = new HttpHeaders();
		for (const key in Environment.API.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Headers, key)) {
				retVal = retVal.append(key, (Environment.API.Headers as { [key: string]: string })[key]);
			}
		}
		return retVal;
	}
}
