import { Component, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
	selector: 'vis-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
})
export class ItemComponent {
	@Input() label?: any;
	@Input() align: 'left' | 'right' = 'right';
	@Input() errors: ValidationErrors | null | undefined;
	@Input() hint?: any;
	@Input() fullWidth = false;
	@Input() fixed = false;
	@Input() stacked = false;

	constructor(private _hostEl: ElementRef<HTMLElement>) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.stacked?.currentValue === true) {
			this._hostEl.nativeElement.classList.add('stacked');
		} else if (changes.stacked?.currentValue === false) {
			this._hostEl.nativeElement.classList.remove('stacked');
		}
	}
}
