import { isEqual, omit } from 'lodash-es';
import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Order } from 'src/app/areas/order/order';
import { OrderFromLocalStorage, Product } from 'src/app/areas/product/product';
import { ApiService } from 'src/app/common/api/api.service';
import { LogCat } from 'src/app/common/logger/logger';
import { LoggerService } from 'src/app/common/logger/logger.service';
import { Environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Settings } from '../settings/settings';
import { SettingsService } from '../settings/settings.service';
import { MINIMUM_CALL_DURATION } from './api';

@Injectable({
	providedIn: 'root',
})
export class ProductApiService extends ApiService {
	_products$ = new BehaviorSubject<Product[]>([]);

	constructor(
		public _http: HttpClient,
		public _logger: LoggerService,
		private _settings: SettingsService
	) {
		super(_http, _logger);
	}

	public FetchProducts() {
		const id = this.StartRequest('getProducts');

		const retVal = this._http.get<Product[]>(Environment.API.Path + '/Product', {
			params: this.Params,
			headers: this.Headers,
		});

		return combineLatest([timer(MINIMUM_CALL_DURATION), retVal]).pipe(
			map((x) => x[1]),
			tap(
				(resp) => {
					resp.map((x) => {
						// Fallback to individual Rx if not present in product
						if (!x.productType) {
							x.productType = 'individual Rx';
						}
					});
					this._products$.next(resp);
					return this._products$;
				},
				(e) => this.LogError(e, LogCat.product, false, true)
			),
			finalize(() => this.EndRequest(id))
		);
	}

	public get Products() {
		return this._products$;
	}

	public HavingSavedProduct() {
		return !isEqual(
			omit(new Order(OrderFromLocalStorage(this._settings.Settings)), [
				'product.productId',
				'product.quantity',
				'type',
				'product.rxPassData.countryCode',
			]),
			omit(new Order(OrderFromLocalStorage(new Settings())), [
				'product.productId',
				'product.quantity',
				'type',
				'product.rxPassData.countryCode',
			])
		);
	}
}
