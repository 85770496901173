import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CorporateCardComponent } from './corporate-card.component';

@NgModule({
	declarations: [CorporateCardComponent],
	imports: [CommonModule],
	exports: [CorporateCardComponent],
})
export class CorporateCardModule {}
