<zui-headerbar
	[ngClass]="{
		'no-event': (tutorial.tutorialState$ | async) === 'OPEN'
	}"
>
	<!-- APP LOGO -->
	<div
		[routerLink]="[routes.product.path + (orderApi.ActiveOrders.length > 0 ? '/edit' : '')]"
		slot="icon"
		class="pointer"
	>
		<zui-icon-zeiss [visTooltip]="app.Name1 + ' ' + app.Name2" size="xl"></zui-icon-zeiss>
	</div>

	<!-- APP NAME -->
	<zui-headerbar-product-name
		class="headerbar-link"
		slot="productName"
		[attr.product-name]="app.Name1"
		[attr.product-sub-name]="app.Name2"
		[routerLink]="[routes.product.path + (orderApi.ActiveOrders.length > 0 ? '/edit' : '')]"
	>
	</zui-headerbar-product-name>

	<!-- MANAGE WHITELIST -->
	<zui-headerbar-icon-button
		emphasis="default"
		slot="iconButtons"
		size="l"
		visTooltip="Manage whitelist"
		id="tut-whitelist-link"
		[attr.ttitle]="'Manage whitelist'"
		[routerLink]="[routes.whitelist.path]"
		#rlaWhitelist="routerLinkActive"
		routerLinkActive
		[routerLinkActiveOptions]="{ exact: true }"
		[visAttribute]="{ emphasis: rlaWhitelist.isActive ? 'selected' : 'default' }"
		*ngIf="auth.canManageWhitelist | async"
	>
		<vis-icon size="l" slot="icon" name="zui-icon-user-user-best-practice-video"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- PRODUCT -->
	<zui-headerbar-icon-button
		emphasis="default"
		slot="iconButtons"
		size="l"
		visTooltip="Place a lens order"
		id="tut-config-link"
		[attr.ttitle]="'Place a lens order'"
		[attr.ttext]="
			'Click here to <b>place an order</b>.' +
			(orderApi.ActiveOrders.length > 0
				? '<br><br>This page is <b>currently disabled</b> because your last order is still being processed.'
				: '')
		"
		[routerLink]="[routes.product.path]"
		#rlaProduct="routerLinkActive"
		routerLinkActive
		[routerLinkActiveOptions]="{ exact: true }"
		[class.forbidden]="!(orderApi.allowPlacingOrder$ | async)"
		[visAttribute]="{
			emphasis: rlaProduct.isActive ? 'selected' : 'default',
			disabled: (orderApi.allowPlacingOrder$ | async) ? null : true
		}"
	>
		<vis-icon size="l" slot="icon" name="cust-lens-add" mode="ng-svg"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- ORDERS -->
	<zui-headerbar-icon-button
		emphasis="default"
		size="m"
		slot="iconButtons"
		[routerLink]="[routes.orders.path]"
		#rlaOrders="routerLinkActive"
		routerLinkActive
		[visAttribute]="{ emphasis: rlaOrders.isActive ? 'selected' : 'default' }"
		[visTooltip]="routes.orders.data.title"
		[attr.badge]="orderApi.ActiveOrders.length"
		[attr.badgecolor]="appColors.info"
		id="tut-orders-link"
		[attr.ttitle]="'Your lenses'"
		[attr.ttext]="'Click here to inspect your lenses.'"
		size="l"
	>
		<vis-icon size="l" slot="icon" name="cust-lens-check" mode="ng-svg"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- TUTORIAL -->
	<zui-headerbar-icon-button
		[class.tutorial-highlight]="tutorial.highlightIcon$ | async"
		emphasis="default"
		size="m"
		slot="iconButtons"
		(click)="tutorial.Open()"
		visTooltip="Help function"
		id="tut-help-link"
		[visAttribute]="{
			emphasis: (tutorial.tutorialState$ | async) === 'OPEN' ? 'selected' : 'default'
		}"
		[attr.ttitle]="'Help'"
		[attr.ttext]="
			'Click there to show up the different tutorial topics like the one you are currently seeing.'
		"
	>
		<vis-icon size="m" slot="icon" name="zui-icon-about"></vis-icon>
	</zui-headerbar-icon-button>

	<!-- USER PROFILE -->
	<zui-user-menu-button
		*ngIf="auth.UserZeissId"
		size="m"
		slot="userMenuButton"
		visTooltip="User settings"
		id="tut-userprofile-link"
		[attr.ttitle]="'User profile'"
		[attr.ttext]="'Click here if you want to sign out or review your user profile.'"
		[attr.user-name]="
			auth.UserZeissId.ZeissIdBase.firstName + ' ' + auth.UserZeissId.ZeissIdBase.lastName
		"
		[attr.user-role]="auth.UserZeissId.ZeissIdOrganisation.name | shorten: 21"
		(click)="openUserProfile()"
	>
		<zui-avatar
			[attr.initials]="
				auth.UserZeissId.ZeissIdBase.firstName[0] + auth.UserZeissId.ZeissIdBase.lastName[0]
			"
			slot="avatar"
		></zui-avatar>
	</zui-user-menu-button>
</zui-headerbar>
