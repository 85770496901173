export const Environment = {
	AppMeta: {
		Environment: 'DEV',
	},
	AuthConfig: {
		// ZEISS ID stage environment
		issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
		clientId: 'ac5fb875-7d36-4c5a-9112-c12c4d09621f',
		discoveryDocumentUrl:
			'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn',
		selfServicePortalUrl: 'https://id-stage.zeiss.com/',
	},
	API: {
		Path: 'https://func-dlp-dev-westeu-002.azurewebsites.net/api',
		Headers: {
			Authorization: '',
		},
		Params: {},
		Create: {
			Path: 'https://func-eop-ac-dev-westeu-002.azurewebsites.net/api',
			Headers: {
				'x-functions-key': 'N8ZfrBxuQLDrNBlJTKnrt55KWjmgGyGk9JxBcW_D2QI8AzFuC5UJFA==',
			},
			LegalDocument: '/legal-document',
			TermsOfUse: '/Developer%20TermsofUse%20Form%20v2%20(2-6-23)%20(002).pdf',
			Authorization: '/Developer%20Authorization%20Form%20v2%20(2-6-23)_with_hyperlinks.pdf',
		},
		Whitelist: {
			Path: 'https://esb-dev.zeiss.com',
			AddUser: '/public/api/experience/vis/evt/account-whitelist/user',
			AddUsers: '/public/api/experience/vis/evt/account-whitelist/users',
			DownloadCsv: '/public/api/experience/vis/evt/account-whitelist/users',
			Headers: {
				'Ocp-Apim-Subscription-Key': '18e97f624b6447e5914511bfcd6d9308',
			},
		},
	},
	RXPassUploadMaxFileSizeinBytesBinary: 8388608,
	RXPassUploadMaxFileSizeinMBText: '8MB',
	appInsights: {
		activated: false,
		instrumentationKey: '74798491-6f79-48cb-8208-b258b242870e',
	},
	AzureAppConfig: {
		ConnectionString:
			'Endpoint=https://appcs-evt-dev-westeu.azconfig.io;Id=drW8-l9-s0:D9Pyi5RGr7++GWO6lp7C;Secret=N6SVA4VZbcrjs9Oc9cRYZBqSwHoJSXD8hh0CZZslQQI=',
	},
};
