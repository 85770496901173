import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import { Environment } from '../../../environments/environment';
import { ApiVersion } from '../../../environments/shared';
import { LogCat, LogLevel } from '../logger/logger';
import { LoggerService } from '../logger/logger.service';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class WhitelistApiService extends ApiService {
	constructor(public http: HttpClient, public logger: LoggerService) {
		super(http, logger);
	}

	public addToWhitelist(email: string, name?: string) {
		const id = this.StartRequest('addToWhitelist');

		let headers = new HttpHeaders();
		for (const key in Environment.API.Whitelist.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Whitelist.Headers, key)) {
				headers = headers.append(
					key,
					(Environment.API.Whitelist.Headers as { [key: string]: string })[key]
				);
			}
		}
		headers = headers.append('Authorization', Environment.API.Headers.Authorization);

		return this._http
			.post<any>(
				`${Environment.API.Whitelist.Path}${Environment.API.Whitelist.AddUser}`,
				{
					email,
					name,
				},
				{
					params: this.Params,
					headers,
				}
			)
			.pipe(
				tap(
					() => {
						this.logger.Log('Added to whitelist successfully', LogCat.whitelist, {
							level: LogLevel.success,
							showToast: true,
						});
					},
					(e) => this.LogError(e, LogCat.whitelist, false, true)
				),
				finalize(() => this.EndRequest(id))
			);
	}

	public getWhitelistRight(zeissId: number) {
		return this.getUserRoles(zeissId).pipe(
			map((rights) => {
				const index = rights.findIndex((r) => r === 1);
				return index > -1;
			})
		);
	}

	public getUserRoles(zeissId: number) {
		const id = this.StartRequest('getUserRoles');

		let headers = new HttpHeaders();
		for (const key in Environment.API.Create.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Create.Headers, key)) {
				headers = headers.append(
					key,
					(Environment.API.Create.Headers as { [key: string]: string })[key]
				);
			}
		}
		headers = headers.append('Authorization', Environment.API.Headers.Authorization);

		return this._http
			.get<RoleAccountResponse>(
				`${Environment.API.Create.Path}${ApiVersion['v1.0']}/account/roles/${zeissId}`,
				{
					params: this.Params,
					headers,
				}
			)
			.pipe(
				map((res) => {
					return res.rights;
				}),
				tap(
					() => {},
					(e) => this.LogError(e, LogCat.product, false, true)
				),
				finalize(() => this.EndRequest(id))
			);
	}

	public downloadCSV() {
		const id = this.StartRequest('downloadCSV');

		let headers = new HttpHeaders();
		for (const key in Environment.API.Whitelist.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Whitelist.Headers, key)) {
				headers = headers.append(
					key,
					(Environment.API.Whitelist.Headers as { [key: string]: string })[key]
				);
			}
		}
		headers = headers.append('Authorization', Environment.API.Headers.Authorization);
		headers = headers.append('Accept', 'text/csv');

		return this._http
			.get<any>(`${Environment.API.Whitelist.Path}${Environment.API.Whitelist.DownloadCsv}`, {
				params: this.Params,
				headers,
				responseType: 'blob' as 'json',
			})
			.pipe(
				tap(
					(response) => {
						const dataType = response.type;
						const binaryData = [];
						binaryData.push(response);
						let downloadLink = document.createElement('a');
						downloadLink.href = window.URL.createObjectURL(
							new Blob(binaryData, { type: dataType })
						);
						downloadLink.setAttribute('download', 'whitelist.csv');
						document.body.appendChild(downloadLink);
						downloadLink.click();
						document.body.removeChild(downloadLink);
						this.logger.Log('Whitelist downloaded successful', LogCat.whitelist, {
							level: LogLevel.success,
							showToast: true,
						});
					},
					(e) => this.LogError(e, LogCat.whitelist, false, true)
				),
				finalize(() => this.EndRequest(id))
			);
	}

	public addUsersToWhitelist(users: { mail: string; name?: string }[]) {
		const id = this.StartRequest('addUsersToWhitelist');

		const body = users.map((u) => {
			return {
				email: u.mail,
				name: u.name,
			};
		});

		let headers = new HttpHeaders();
		for (const key in Environment.API.Whitelist.Headers) {
			if (Object.prototype.hasOwnProperty.call(Environment.API.Whitelist.Headers, key)) {
				headers = headers.append(
					key,
					(Environment.API.Whitelist.Headers as { [key: string]: string })[key]
				);
			}
		}
		headers = headers.append('Authorization', Environment.API.Headers.Authorization);

		return this._http
			.post<any>(`${Environment.API.Whitelist.Path}${Environment.API.Whitelist.AddUsers}`, body, {
				params: this.Params,
				headers,
			})
			.pipe(
				tap(
					(result: { added: string[]; alreadyExists: string[] }) => {
						console.log('White List Add Result', result);
						// If the API returns with a success but no addresses are added to the list and no addresses are already on the list, then we show a warning toast
						if (
							result &&
							Array.isArray(result.added) &&
							result.added.length === 0 &&
							Array.isArray(result.alreadyExists) &&
							result.alreadyExists.length === 0
						)
							this.logger.Log('Nothing added to whitelist! Check CSV file.', LogCat.whitelist, {
								level: LogLevel.warning,
								showToast: true,
							});
						// If the API returns with a success and addresses are added to the list, then we show a success toast
						if (result && Array.isArray(result.added) && result.added.length > 0)
							this.logger.Log(`Added ${result.added.length} to whitelist`, LogCat.whitelist, {
								level: LogLevel.success,
								showToast: true,
							});
						// If the API returns with a success and addresses are already on the list, then we show a warning toast
						if (result && Array.isArray(result.alreadyExists) && result.alreadyExists.length > 0)
							this.logger.Log(
								`${result.alreadyExists.length} addresses are already on the list`,
								LogCat.whitelist,
								{
									level: LogLevel.warning,
									showToast: true,
								}
							);
					},
					(e) => this.LogError(e, LogCat.whitelist, false, true)
				),
				finalize(() => this.EndRequest(id))
			);
	}
}

export interface RoleAccountResponse {
	zeissIdAccountId: string;
	rights: number[];
	email: string;
}
