import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormErrorModule } from '../form-validation/form-error.module';
import { ItemComponent } from './item.component';

@NgModule({
	declarations: [ItemComponent],
	imports: [CommonModule, FormErrorModule],
	exports: [ItemComponent],
})
export class ItemModule {}
