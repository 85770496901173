import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { IconModule } from '../icon/icon.module';
import { HrefExternalComponent } from './href-external.component';

@NgModule({
	declarations: [HrefExternalComponent],
	imports: [CommonModule, IconModule],
	exports: [HrefExternalComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HrefExternalModule {}
