<div
	class="app"
	[style.display]="(auth.Authenthicated | async) && (hasRoleToAccess$ | async) ? '' : 'none'"
>
	<div class="tutorial-general-spot-wrapper">
		<div id="tutorial-general-spot"></div>
	</div>

	<vis-headerbar></vis-headerbar>

	<div
		class="content-container"
		(scroll)="tutorial.scrollY$.next($any($event))"
		(click)="tutorialDismiss()"
	>
		<div class="content" [@routerAnimation]="router.activatedRouteData.animationHierarchy ?? -1">
			<router-outlet #router="outlet"></router-outlet>
		</div>
	</div>
</div>

<vis-splash
	*ngIf="!(auth.Authenthicated | async) || !(hasRoleToAccess$ | async)"
	[userHasAccessRights]="(hasRoleToAccess$ | async) || false"
></vis-splash>

<vis-floating-menu *ngIf="auth.Authenthicated | async"></vis-floating-menu>

<vis-toast></vis-toast>
