import { Component } from '@angular/core';
import { AppColors } from 'src/app/common/app';
import { AuthService } from 'src/app/common/auth/auth.service';
import { Build } from 'src/environments/build';
import { Environment } from 'src/environments/environment';
import { App } from 'src/environments/shared';
import { SettingsService } from '../settings/settings.service';

@Component({
	selector: 'vis-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
	busy = false;
	appColors = AppColors;
	app = App;
	build = Build;
	envDisplay = Environment.AppMeta.Environment === 'PROD' ? '' : Environment.AppMeta.Environment;

	constructor(public auth: AuthService, public settings: SettingsService) {}

	signOut() {
		this.busy = true;
		localStorage.clear(); // Remove users locally stored data
		setTimeout(() => this.auth.SignOut(), 1000);
	}
}
