import { NgModule } from '@angular/core';
import { CheckboxDirective } from './checkbox-accessor.directive';
import { CommentDirective } from './comment-accessor.directive';
import { RadioButtonDirective } from './radio-button-accessor.directive';
import { RadioButtonGroupDirective } from './radio-button-group-accessor.directive';
import { SelectDirective } from './select-accessor.directive';
import { TextfieldDirective } from './textfield-accessor.directive';

@NgModule({
	declarations: [
		TextfieldDirective,
		CheckboxDirective,
		SelectDirective,
		RadioButtonDirective,
		RadioButtonGroupDirective,
		CommentDirective,
	],
	exports: [
		TextfieldDirective,
		CheckboxDirective,
		SelectDirective,
		RadioButtonDirective,
		RadioButtonGroupDirective,
		CommentDirective,
	],
})
export class AccessorsModule {}
